import React, {useState} from "react";
import PropTypes from "prop-types";
import Helpers from "../../services/Helpers";

export default function GroceryItemForm(props) {
  const {isEmpty} = Helpers();
  const [text, setText] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    props.createItem({text: text}, () => {
      setText("");
    });
  }

  return (

<form onSubmit={handleSubmit}>
        <div className="input-group mt-2 mb-4 search-wrap px-md-5 d-print-none px-2">
          <input
            className="form-control input-search border"
            type="text"
            placeholder="Add an item to your list"
            value={text}
            onChange={e => setText(e.target.value)}
          />
          {isEmpty(text) ? (
            <button className="btn btn-primary" type="button" disabled={true}><i className="fas fa-plus-circle"></i> Add</button>
          ) : (
            <button className="btn btn-primary" type="submit"><i className="fas fa-plus-circle"></i> Add</button>
          )}
          </div>
      </form>


  );
}

GroceryItemForm.propTypes = {
  createItem: PropTypes.func,
}