import StartTrialForm from "./StartTrialForm";

export default function StartTrialModal({ memberStore, subStore, hideModal }) {
  return (
    <div id="start-trial-modal" className="modal" tabIndex={-1} data-bs-backdrop="static">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">Start free trial</h1>
            <button type="button" className="btn-close btn-close-black" onClick={() => hideModal("start-trial-modal")} />
          </div>
          <div className="modal-body">
            <StartTrialForm memberStore={memberStore} subStore={subStore} hide={() => hideModal("start-trial-modal")} />
          </div>
        </div>
      </div>
    </div>
  );
}
