import "./Search.css";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import useUniversalSearch from "../../services/stores/UniversalSearchStore";
import { useHistory, useLocation } from "react-router-dom";
import Pager from "./components/Pager";
import Facets from "./components/Facets";
import ActiveFilter from "./components/ActiveFilter";
import Document from "./components/Document";
import NoResults from "./components/NoResults";
import Constants from "../../services/Constants";

export default function UniversalSearch(props) {
  let location = useLocation();
  let history = useHistory();
  const { searchOrigins } = Constants();
  const store = useUniversalSearch(props.token, props.config, props.memberStore.member.preferences);

  useEffect(() => {
    document.title = `Search - Clean Food Dirty Girl`;
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const v = searchParams.get("v");
    if ([null, "search"].indexOf(v) !== -1) {
      // We want to search
      if (!props.memberStore.waitingForMember) {
        const validSearch = store.validSearch(location.search)
        if (location.search === validSearch) {
          store.search(location.search);
        } else {
          history.replace(`${location.pathname}${validSearch}`)
        }
      }
    } else {
      // We are accessing another view such as the "plan" or "batching" view from the Magic Meal Planner
    }
  }, [location, props.memberStore.waitingForMember]);

  function documentTypeName() {
    let result = "";
    if (store.state.activeDocumentType === "WizardPlan") {
      result = "Meal plans";
    } else if (store.state.activeDocumentType === "Recipe") {
      result = "Recipes";
    } else if (store.state.activeDocumentType === "Video") {
      result = "Videos";
    }
    return result;
  }

  function appliedFilters() {
    const filters = [];
    if (store.state.recommendedOnly) {
      filters.push(
        <ActiveFilter key="documentType" filter="Planner friendly meals" disabled={store.state.loading} remove={() => history.push(store.nextLocation(store.searchActions.RECOMMENDED_ONLY))} />
      );
    }
    if (props.config.searchOrigin !== searchOrigins.PLANNER && store.state.activeDocumentType !== "") {
      filters.push(
        <ActiveFilter key="documentType" filter={documentTypeName()} disabled={store.state.loading} remove={() => history.push(store.nextLocation(store.searchActions.FACET, { facetType: "documentType", facetName: store.state.activeDocumentType }))} />
      );
    }
    if (store.state.activeToolIds.length > 0) {
      const tools = props.cache.tools.filter(t => store.state.activeToolIds.indexOf(t.id) !== -1);
      for (let tool of tools) {
        filters.push(
          <ActiveFilter key={`tool-${tool.id}`} filter={tool.name} disabled={store.state.loading} remove={() => history.push(store.nextLocation(store.searchActions.FACET, { facetType: "tool", id: tool.id }))} />
        );
      }
    }
    if (store.state.activeCharacteristicIds.length > 0) {
      const characteristics = props.cache.characteristics.filter(c => store.state.activeCharacteristicIds.indexOf(c.id) !== -1);
      for (let characteristic of characteristics) {
        filters.push(
          <ActiveFilter key={`characteristic-${characteristic.id}`} filter={characteristic.name} disabled={store.state.loading} remove={() => history.push(store.nextLocation(store.searchActions.FACET, { facetType: "characteristic", id: characteristic.id }))} />
        );
      }
    }
    if (store.state.activePlanType !== "") {
      filters.push(
        <ActiveFilter key="planType" filter={store.state.activePlanType} disabled={store.state.loading} remove={() => history.push(store.nextLocation(store.searchActions.FACET, { facetType: "planType", facetName: store.state.activePlanType }))} />
      );
    }
    if (store.state.activeTagIds.length > 0) {
      const tags = props.cache.tags.filter(t => store.state.activeTagIds.indexOf(t.id) !== -1);
      for (let tag of tags) {
        filters.push(
          <ActiveFilter key={`tag-${tag.id}`} filter={tag.tag} disabled={store.state.loading} remove={() => history.push(store.nextLocation(store.searchActions.FACET, { facetType: "tag", id: tag.id }))} />
        );
      }
    }
    return (
      <div className="my-3 mx-2 mx-lg-0">
        <button
          className="btn btn-secondary me-1 mb-1"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#filters"
        >
          <i className="fas fa-sliders-h" /> Filter the goods
        </button>
        {filters}
      </div>
    );
  }

  const results = store.state.results.map(r => (
    <Document
      key={r.id}
      authenticated={props.authenticated}
      member={props.memberStore.member}
      stashStore={props.stashStore}
      modals={props.modals}
      document={r}
      add={props.memberStore.member.hasMP ? props.config.add : null}
    />
  ));

  return (
    <main id="search" className="container-lg px-0">
      {props.banner}
      <form className="s" onSubmit={e => {
        e.preventDefault();
        history.push(store.nextLocation(store.searchActions.SEARCH_BUTTON));
      }}>
        <div className="row pt-3 py-lg-2 mx-2 mx-md-5 ">
          <div className="col ps-0 ">
            <input
              className="form-control form-control-lg input-search"
              type="search"
              placeholder=""
              value={store.state.terms}
              onChange={e => store.updateTerms(e.target.value)}
            />
          </div>
          <div className="col-auto px-0">
            <button
              type="submit"
              className="btn btn-lg btn-primary"
              disabled={store.state.loading}
            >
              <span className="d-md-none fas fa-search" />
              <span className="d-none d-md-inline">Find your yum</span>
            </button>
          </div>
        </div>
      </form>
      {appliedFilters()}
      <ul className="nav nav-tabs nav-fill mt-3">
        <li className="nav-item">
          <a className={store.state.section === store.sections.ALL ? "nav-link active" : "nav-link"} href="#" onClick={e => {
            e.preventDefault();
            if (!store.state.loading) {
              history.push(store.nextLocation(store.searchActions.SECTION_ALL));
            }
          }}><i className="fas fa-magnifying-glass" /><span className="d-none d-sm-inline"> All</span> <span className="facet-count">{store.state.total}</span></a>
        </li>
        {props.config.searchOrigin === searchOrigins.PLANNER ? null : (
          <li className="nav-item">
            <a className={store.state.section === store.sections.PINNED ? "nav-link active" : "nav-link"} href="#"
              onClick={e => {
                e.preventDefault();
                if (!store.state.loading) {
                  history.push(store.nextLocation(store.searchActions.SECTION_PINNED));
                }
              }}><i className="fas fa-thumbtack" /><span className="d-none d-sm-inline"> Pinned</span> <span
                className="facet-count">{store.state.totalPinned}</span></a>
          </li>
        )}
        <li className="nav-item">
          <a className={store.state.section === store.sections.SAVED ? "nav-link active" : "nav-link"} href="#" onClick={e => {
            e.preventDefault();
            if (!store.state.loading) {
              history.push(store.nextLocation(store.searchActions.SECTION_SAVED));
            }
          }}><i className="fa-solid fa-folder-bookmark" /><span className="d-none d-sm-inline"> Saved</span> <span className="facet-count">{store.state.totalSaved}</span></a>
        </li>
        <li className="nav-item">
          <a className={store.state.section === store.sections.LOVED ? "nav-link active" : "nav-link"} href="#" onClick={e => {
            e.preventDefault();
            if (!store.state.loading) {
              history.push(store.nextLocation(store.searchActions.SECTION_LOVED));
            }
          }}><i className="fas fa-heart" /><span className="d-none d-sm-inline"> Loved</span> <span className="facet-count">{store.state.totalLoved}</span></a>
        </li>
      </ul>
      <div className="container-lg bg-white py-2 p-sm-3 py-sm-3">
        <div className="row search-results">
          {store.state.loading ? (
            <div className="text-center">
              <span className="spinner-border" />
            </div>
          ) : results}
        </div>
        <NoResults store={store} searchOrigin={props.config.searchOrigin} />
        <Pager
          store={store}
        />
      </div>

      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="filters"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title">Filters</h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" />
        </div>
        {store.filtersAreNotEmpty() ? (
          <button
            className="btn btn-outline-primary mx-4"
            disabled={store.state.loading}
            type="button" onClick={() => history.push(store.nextLocation(store.searchActions.CLEAR_FILTERS))}
          >
            Clear filters
          </button>
        ) : null}
        <div className="offcanvas-body">
          <Facets cache={props.cache} store={store} searchOrigin={props.config.searchOrigin} />
        </div>
      </div>

    </main>
  );
}

UniversalSearch.propTypes = {
  token: PropTypes.string,
  authenticated: PropTypes.bool,
  cache: PropTypes.object,
  memberStore: PropTypes.object,
  banner: PropTypes.element,
  modals: PropTypes.object,
  config: PropTypes.object,
  stashStore: PropTypes.object,
}