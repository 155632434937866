import React from "react";
import PropTypes from "prop-types";
import Constants from "../../services/Constants";
import Helpers from "../../services/Helpers";

export default function ReplaceWarning(props) {
  const {documentTypes, MMP_SECTION_CAP: mmpSectionCap} = Constants();
  const {isNotEmpty} = Helpers();

  function main() {
    let result = null;
    if (props.store.document.documentType === documentTypes.WIZARD_PLAN && props.store.document.hasOwnProperty("wizardPlanMeals")) {
      const replacements = [];
      for (const [i, section] of props.store.plan.sections.entries()) {
        if (i < 5) {
          // For the first 5 sections, we replace one meal if it's full
          if (section.meals.length === mmpSectionCap) {
            const meal = section.meals[section.meals.length-1];
            replacements.push({section: section.name, wizardPlanMeal: props.store.document.wizardPlanMeals[i], meal: meal});
          }
        } else {
          if (props.store.document.wizardPlanMeals.length > 5 && i === 5) {
            // We're adding bonus recipes to the 6th section
            const bonusesCount = props.store.document.wizardPlanMeals.length-5;
            if ((section.meals.length+bonusesCount) > mmpSectionCap) {
              const replacedMealsCount = section.meals.length+bonusesCount-mmpSectionCap;
              const replacedMeals = section.meals.slice(mmpSectionCap-replacedMealsCount);
              for (const [j, meal] of replacedMeals.entries()) {
                replacements.push({section: section.name, wizardPlanMeal: props.store.document.wizardPlanMeals[5+j], meal: meal});
              }
            }
          }
        }
      }
      if (replacements.length > 0) {
        result = (
          <div className="alert alert-warning my-3" role="alert">
            <i className="fas fa-exclamation-triangle text-yellow"/> Adding Meal plan <strong>{props.store.document.name}</strong> will replace {replacements.length === 1 ? "this recipe" : "these recipes"}:
            <ul>
              {replacements.map((r, i) => (
                <li key={i}><strong>{r.wizardPlanMeal.name}</strong> will replace <strong>{r.meal.name}</strong> in <strong>{r.section}</strong></li>
              ))}
            </ul>
          </div>
        );
      }
    } else if (props.readyToReplace) {
      const selectedSection = props.store.plan.sections.find(s => s.day === props.day);
      if (isNotEmpty(selectedSection) && selectedSection.meals.length === mmpSectionCap) {
        result = (
          <div className="alert alert-warning my-3" role="alert">
            <i className="fas fa-exclamation-triangle text-yellow"/> Adding <strong>{props.store.document.name}</strong> will replace <strong>{selectedSection.meals[selectedSection.meals.length - 1].name}</strong>.
          </div>
        );
      }
    }
    return result;
  }

  return main();
}

ReplaceWarning.propTypes = {
  store: PropTypes.object,
  day: PropTypes.number,
  ready: PropTypes.bool,
}