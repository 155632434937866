import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Constants from "../../services/Constants";
import PurchaseConfirmationCard from "./PurchaseConfirmationCard";
import { useHistory } from "react-router-dom";
import SnailButton from "../components/SnailButton";

export default function PurchaseConfirmation(props) {
  let history = useHistory();
  const { subscriptionMessages } = Constants();
  const [working, setWorking] = useState(false);

  useEffect(() => {
    if (working) {
      props.subStore.addSubscription(props.plan, () => {
        setWorking(false);
      });
    }
  }, [working]);

  useEffect(() => {
    // We know the purchase was successful once the member has MP
    if (props.member.hasMP || props.member.hasRV) {
      history.push(`/thank-you?p=${props.plan.internalName.toLowerCase()}`);
    }
  }, [props.member]);

  const defaultCard = props.subStore.creditCards.find((cc) => cc.status === 3);

  function handleSubmit(event) {
    event.preventDefault();
    setWorking(true);
  }

  function message() {
    let result = null;
    if (props.subStore.subscriptionMessage === subscriptionMessages.ERROR_CARD) {
      result = (
        <div className="alert alert-primary" role="alert">
          <span style={{ verticalAlign: "middle" }}>Oh no! Your card was declined. Please</span>{" "}
          <button type="button" className="btn btn-link p-0 m-0" onClick={props.back} style={{ verticalAlign: "middle" }}>
            change your card
          </button>{" "}
          <span style={{ verticalAlign: "middle" }}>and try again</span>.
        </div>
      );
    } else if (props.subStore.subscriptionMessage === subscriptionMessages.ERROR) {
      result = (
        <div className="alert alert-primary" role="alert">
          Something went wrong, email us at <a href="mailto:support@cleanfooddirtygirl.com">support@cleanfooddirtygirl.com</a> and we'll help you out.
        </div>
      );
    }
    return result;
  }

  return (
    <div>
      {message()}
      <form onSubmit={handleSubmit}>
        <PurchaseConfirmationCard plan={props.plan} card={defaultCard} />
        <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
          <SnailButton
            className="btn btn-primary btn-lg fw-bold"
            type="submit"
            text="Complete purchase"
            disabled={working}
            workingTexts={["Purchasing...", "Hang tight...", "Still working..."]}
            working={working}
          />
        </div>
      </form>
    </div>
  );
}

PurchaseConfirmation.propTypes = {
  member: PropTypes.object,
  subStore: PropTypes.object,
  plan: PropTypes.object,
  back: PropTypes.func,
};
