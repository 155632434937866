import "./NewSubscriptionForm.css";
import React, {useState} from "react";
import PropTypes from "prop-types";

export default function StartTrialForm(props) {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [working, setWorking] = useState(false);

  function startTrial(event) {
    event.preventDefault();
    setWorking(true);
    props.subStore.addSubscription({internalName: "PFL_TRIAL"}, () => {
      setWorking(false);
      setTermsAccepted(false);
      props.hide();
    });
  }

  function startTrialButton() {
    let result;
    if (working) {
      result = (
        <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
          <button
            type="button"
            className="btn btn-primary btn-lg fw-bold"
            disabled={true}
          >
            <span className="spinner-border spinner-border-sm"/>
            <span className="ms-1">Starting your trial...</span>
          </button>
        </div>
      );
    } else if (termsAccepted) {
      result = (
        <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
          <button
            type="button"
            className="btn btn-primary btn-lg fw-bold"
            onClick={startTrial}
          >
            Let's do this!
          </button>
        </div>
      );
    } else {
      result = (
        <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
          <button
            type="button"
            className="btn btn-primary btn-lg fw-bold"
            disabled={true}
          >
            Let's do this!
          </button>
        </div>
      );
    }
    return result;
  }

  const terms = (
    <>
      <p className="form-check mt-1"><small>
        <input
          className="form-check-input cursor-pointer"
          type="checkbox"
          checked={termsAccepted}
          id="terms"
          onChange={e => setTermsAccepted(e.target.checked)}
        />

        <label className="form-check-label cursor-pointer" htmlFor="terms">
          I have read, understood and agree to the <a
          href="https://cleanfooddirtygirl.com/mealplans-waiver-refund-policy/" target="_blank"
          rel="noopener noreferrer" className="text-black text-decoration-underline">terms of purchase and waiver</a>.
        </label>
      </small>
      </p>
    </>
  );

  return (
    <div>
      <div className="p-md-3">
      <div className="intro">
        <p><strong>Try Clean Food Dirty Girl for ten days absolutely free.</strong> Unlimited access - no credit card required.</p>
        <p>The trial <strong>does not</strong> automatically upgrade to a paid membership after 10 days, but you can choose to upgrade at any time.</p>
        <p>The trial cannot be renewed.</p>
      </div>
        {terms}
        <div className="mt-4">
          {startTrialButton()}
        </div>
      </div>
    </div>
  );
}

StartTrialForm.propTypes = {
  memberStore: PropTypes.object,
  subStore: PropTypes.object,
  hide: PropTypes.func,
}