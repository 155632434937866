import Constants from "./Constants";

export default function Helpers() {
  const { pageTitles } = Constants();
  function assetUrl(key) {
    let result = "";
    if (isNotEmpty(key)) {
      result = key.indexOf("https://") === -1 ? `https://assets.cleanfooddirtygirl.com/${key}` : key;
    }
    return result;
  }

  function buildQuery(search) {
    const searchParams = new URLSearchParams(search);
    const toolIds = searchParams.get("tools");
    const characteristicIds = searchParams.get("allergens");
    const tagIds = searchParams.get("categories");
    return {
      section: searchParams.get("tab"),
      recommendedOnly: searchParams.get("mmp-friendly") === "true",
      terms: searchParams.get("query"),
      offset: searchParams.get("offset"),
      documentType: searchParams.get("content"),
      planType: searchParams.get("wp-type"),
      toolIds: toolIds === "" ? null : toolIds.split(","),
      characteristicIds: characteristicIds === "" ? null : characteristicIds.split(","),
      tagIds: tagIds === "" ? null : tagIds.split(","),
    };
  }

  function day(displayOrder) {
    let day;
    switch (displayOrder) {
      case 1:
        day = "MONDAY";
        break;
      case 2:
        day = "TUESDAY";
        break;
      case 3:
        day = "WEDNESDAY";
        break;
      case 4:
        day = "THURSDAY";
        break;
      case 5:
        day = "FRIDAY";
        break;
      default:
        day = "BONUS";
    }
    return day;
  }

  function decodeJSON(string) {
    let result = null;
    try {
      result = JSON.parse(atob(string));
    } catch (e) {
      // invalid json, we do nothing
    }
    return result;
  }

  function encodeJSON(data) {
    const string = JSON.stringify(data);
    return btoa(string);
  }

  function formatDateInternational(timestamp) {
    const date = new Date(timestamp * 1000);
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
  }

  function handleError(err) {
    let destination = "/error";
    if (isNotEmpty(err) && err.hasOwnProperty("response") && err.response.hasOwnProperty("status")) {
      if (err.response.status === 401) {
        destination = "/";
      } else if (err.response.status === 404) {
        destination = "/404";
      }
    }
    if (destination === "/error") {
      if (window.location.href.indexOf("/error") === -1) {
        window.location.href = destination;
      }
    } else {
      window.location.href = destination;
    }
  }

  function monthNumberToText(monthNumber) {
    let month;
    switch (monthNumber) {
      case "01":
        month = "January";
        break;
      case "02":
        month = "February";
        break;
      case "03":
        month = "March";
        break;
      case "04":
        month = "April";
        break;
      case "05":
        month = "May";
        break;
      case "06":
        month = "June";
        break;
      case "07":
        month = "July";
        break;
      case "08":
        month = "August";
        break;
      case "09":
        month = "September";
        break;
      case "10":
        month = "October";
        break;
      case "11":
        month = "November";
        break;
      case "12":
        month = "December";
        break;
      default:
        month = "January";
    }
    return month;
  }

  function isEmpty(data) {
    return [null, undefined, ""].includes(data);
  }

  function isNotEmpty(data) {
    return !isEmpty(data);
  }

  function arrElementValue(arr, i) {
    return arr !== undefined ? arr[i] : false;
  }

  function groupByArray(xs, key) {
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);
  }

  // Creates a new list with the separator interposed between elements.
  function intersperse(arr, el) {
    var res = [],
      i = 0;
    if (i < arr.length) res.push(arr[i++]);
    while (i < arr.length) res.push(el, arr[i++]);
    return res;
  }

  function capitalizeFirstLetter(text) {
    let result = "";
    if (isNotEmpty(text)) {
      result = text.charAt(0).toUpperCase() + text.slice(1);
    }
    return result;
  }

  function makeUrlLookGood(id, text) {
    const url = text.replace(/ /g, "-").replace(/[^a-zA-Z0-9-_]/g, "");
    return `${id}-${url}`.toLocaleLowerCase();
  }

  function safeFileName(text) {
    const safe = text.replace(/ /g, "_").replace(/[^a-zA-Z0-9-_]/g, "");
    return safe.replace(/___/g, "_").replace(/__/g, "_");
  }

  function makeMinutesLookGood(minutes) {
    let result = "";
    if (isNotEmpty(minutes)) {
      if (minutes > 59) {
        if (minutes % 60 === 0) {
          result = `${minutes / 60} Hr.`;
        } else {
          const h = minutes / 60;
          const m = (h - Math.floor(h)) * 60;
          result = `${Math.floor(h)} Hr. ${Math.round(m)} Min.`;
        }
      } else {
        result = `${minutes} Min.`;
      }
    }
    return result;
  }

  function pageTitle(path) {
    let result = "Clean Food Dirty Girl";
    if (isNotEmpty(path)) {
      const pathParts = path.split("/");
      if (pathParts.length > 0) {
        const pageTitle = pageTitles.find((pt) => pt.path === pathParts[pathParts.length - 1]);
        if (isNotEmpty(pageTitle)) {
          result = pageTitle.title;
        }
      }
    }
    return result;
  }

  function range(start, end, step = 1) {
    let output = [];

    if (typeof end === "undefined") {
      end = start;
      start = 0;
    }

    for (let i = start; i < end; i += step) {
      output.push(i);
    }

    return output;
  }

  function reviewsAverage(reviews) {
    if (reviews.length > 0) {
      return (
        reviews.reduce((sum, r) => {
          return sum + parseFloat(r.stars);
        }, 0) / reviews.length
      );
    } else {
      return null;
    }
  }

  function shortenedText(text, limit) {
    let result = text;
    if (isNotEmpty(text) && text.length > limit) {
      result = `${text.substring(0, limit)}...`;
    }
    return result;
  }

  function splitHashtag(hashtag) {
    let result = hashtag;
    if (isNotEmpty(hashtag)) {
      // Handle BBQ special case
      if (hashtag.indexOf("BBQ") !== -1) {
        hashtag = hashtag.replace("BBQ", "Bbq");
      }
      const words = hashtag.match(/(\d+|[A-Z][a-z]+|[A-Z]|[a-z])/g);
      if (isNotEmpty(words)) {
        // Handle single "s" that denotes possession
        const sIndex = words.indexOf("s");
        if (sIndex === -1) {
          result = words.map((w) => (w.length === 1 ? w.toLowerCase() : w)).join(" ");
        } else {
          result = "";
          for (const [index, word] of words.entries()) {
            if (index === sIndex - 1) {
              result += word;
            } else {
              result += word + " ";
            }
          }
        }
        // Handle BBQ special case
        result = result.replace("Bbq", "BBQ");
        // Remove trailing whitespace
        result = result.trim();
      }
    }
    return result;
  }

  function tester(email) {
    let result = false;
    if (isNotEmpty(email)) {
      result = [].indexOf(email.toLowerCase()) !== -1;
    }
    return result;
  }

  function validateEmail(email) {
    let valid;
    if (isNotEmpty(email)) {
      if (email.length > 3 && email.substr(-4) === ".con") {
        // Handle .con
        valid = false;
      } else if (email.indexOf("@gnail") !== -1) {
        // Handle @gnail
        valid = false;
      } else {
        const emailMatch = email.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
        valid = emailMatch !== null;
      }
    } else {
      valid = false;
    }
    return valid;
  }

  function paddedLast4(unpaddedLast4) {
    let last4;
    switch (unpaddedLast4.toString().length) {
      case 4:
        last4 = unpaddedLast4;
        break;
      case 3:
        last4 = `0${unpaddedLast4}`;
        break;
      case 2:
        last4 = `00${unpaddedLast4}`;
        break;
      case 1:
        last4 = `000${unpaddedLast4}`;
        break;
      default:
        last4 = "0000";
        break;
    }
    return last4;
  }

  /**
   * Toggle a recipe checkbox
   *
   * The boolean is inverted in the array and the array is sent server side to be persisted. The new array is sent to a
   * parent function which takes care of saving data and refreshing the state.
   *
   * @param {number} recipeId - id of the recipe
   * @param {array} checkboxes - original array of checkboxes
   * @param {number} i - position of the checkbox in the checkboxes array
   * @param {function} update - function called to update the checkboxes
   */
  function toggleCheckbox(recipeId, checkboxes, i, update) {
    const result = [];
    for (let c = 0; c < checkboxes.length; c++) {
      if (c === i) {
        result.push(!checkboxes[c]);
      } else {
        result.push(checkboxes[c]);
      }
    }
    update(recipeId, result);
  }

  function profilePhoto(member) {
    let result = "";
    if (isNotEmpty(member.id)) {
      result = isEmpty(member.photo) ? "https://assets.cleanfooddirtygirl.com/static/profile-image.jpg" : assetUrl(member.photo);
    }
    return result;
  }

  return {
    arrElementValue,
    assetUrl,
    buildQuery,
    capitalizeFirstLetter,
    day,
    decodeJSON,
    encodeJSON,
    formatDateInternational,
    groupByArray,
    handleError,
    monthNumberToText,
    isEmpty,
    isNotEmpty,
    intersperse,
    makeMinutesLookGood,
    makeUrlLookGood,
    pageTitle,
    range,
    reviewsAverage,
    safeFileName,
    shortenedText,
    splitHashtag,
    tester,
    validateEmail,
    paddedLast4,
    toggleCheckbox,
    profilePhoto,
  };
}
