import React, {useState} from "react";
import PropTypes from "prop-types";

export default function NewPlanForm(props) {
  const [name, setName] = useState("");
  const [working, setWorking] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setWorking(true);
    props.store.create({name: name}, newPlanId => {
      props.select(newPlanId);
      props.hide();
    });
  }

  const cancelButton = working ? <button type="button" className="btn btn-outline-primary me-2" disabled={true}>Cancel</button> : (
    <button
      type="button"
      className="btn btn-outline-primary me-2"
      onClick={props.hide}
    >
      Cancel
    </button>
  );

  const createButton = working ? (
    <button
      type="button"
      className="btn btn-primary"
      disabled={true}
    >
      <span className="spinner-border spinner-border-sm"/>
      <span className="ms-1">Creating...</span>
    </button>
  ) : <button type="submit" className="btn btn-primary">Create meal plan</button>;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <input
            type="text"
            autoComplete="off"
            className="form-control"
            value={name}
            placeholder="The most tastiest..."
            required={true}
            onChange={e => setName(e.target.value)}
          />
        </div>
        {props.store.state.plans.length > 0 ? cancelButton : null}
        {createButton}
      </form>
    </div>
  );
}

NewPlanForm.propTypes = {
  store: PropTypes.object,
  select: PropTypes.func,
  hide: PropTypes.func,
}