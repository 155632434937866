export default function Constants() {
  const authMessages = {
    AUTHENTICATION_FAILED: "Authentication failed",
    EMAIL_ALREADY_USED: "Email already used",
    SERVER_ERROR: "Server error",
  };
  const collectionTypes = {
    RECIPE: "recipe",
    WIZARD_PLAN: "wizardPlan",
  };
  const contexts = {
    DOCUMENT: "document",
    MAGIC_MEAL_PLANNER: "magicMealPlanner",
    RECIPE: "recipe",
    SEARCH_RESULT: "searchResult",
    STANDALONE_RECIPE: "standaloneRecipe",
    WIZARD_PLAN: "wizardPlan",
  };
  const cookingMethods = {
    INSTANT_POT: "Instant Pot",
    STOVE_TOP: "Stove top",
  };
  const devices = {
    DESKTOP: "Desktop",
    MOBILE: "Mobile",
  };
  const documentTypes = {
    RECIPE: "Recipe",
    VIDEO: "Video",
    WIZARD_PLAN: "WizardPlan",
  };
  const groceriesSourceTypes = {
    WIZARD_PLAN: "WizardPlan",
    WEEKLY_MEAL_PLAN: "MagicMealPlan",
    RECIPE: "Recipe",
  };
  const homepageComponentTypes = {
    BLANK: "Blank",
    COLLECTION: "Collection",
    COOKING_JOURNAL: "Cooking Journal",
    EXTERNAL_LINKS: "External Links",
    FEATURED_WIZARD_PLANS: "Featured Wizard Plans",
    INTERNAL_LINKS: "Internal Links",
    MEMBERS_RECIPE_PHOTOS: "Members' Recipe Photos",
    MOST_LOVED_RECIPES_THIS_WEEK: "Most Loved Recipes This Week",
    NEW_AND_NOTEWORTHY_RECIPES: "New And Noteworthy Recipes",
    QUICK_LINKS: "Quick Links",
    RANDOM_MEMBERS_LOVED_ITEMS: "Random Member's Loved Items",
    RANDOM_MEMBERS_SAVED_ITEMS: "Random Member's Saved Items",
    TRENDING_THIS_WEEK: "Trending This Week",
  };
  const mealTypes = {
    BREAKFAST: "breakfast",
    LUNCH: "lunch",
    DINNER: "dinner",
    EXTRA: "extra",
  };
  const pageTitles = [
    { path: "sign-in", title: "Sign In - Clean Food Dirty Girl" },
    { path: "sign-up", title: "Join Clean Food Dirty Girl Today" },
    { path: "saved", title: "Saved Items - Clean Food Dirty Girl" },
    { path: "meal-planner", title: "Magic Meal Planner - Clean Food Dirty Girl" },
    { path: "wizard-plans", title: "Meal plans - Clean Food Dirty Girl" },
    { path: "featured-wizard-plans", title: "Featured meal plans - Clean Food Dirty Girl" },
    { path: "recipe-vault", title: "Recipe Vault - Clean Food Dirty Girl" },
    { path: "new-and-noteworthy-recipes", title: "New & Noteworthy Recipes - Clean Food Dirty Girl" },
    { path: "featured-recipes", title: "Featured Recipes - Clean Food Dirty Girl" },
    { path: "recipe-collections", title: "Recipe Collections - Clean Food Dirty Girl" },
    { path: "coaching", title: "Get Coached by Molly Patrick" },
    { path: "welcome", title: "Get Coached - Welcome" },
    { path: "drink-less-workshop", title: "Drink Less Workshop with Molly Patrick" },
    { path: "watch-learn", title: "Watch & Learn - Clean Food Dirty Girl" },
    { path: "wfpb-basics", title: "Whole-Food Plant-Based Basics" },
    { path: "inspiring-conversations", title: "Inspiring Conversations" },
    { path: "kitchen-play", title: "Kitchen Play with Molly Patrick" },
    { path: "ingredient-prep-tutorials", title: "Ingredient Prep Tutorials" },
    { path: "ingredients-a-e", title: "Ingredient Prep Tutorials A-E" },
    { path: "ingredients-f-j", title: "Ingredient Prep Tutorials F-J" },
    { path: "ingredients-k-o", title: "Ingredient Prep Tutorials K-O" },
    { path: "ingredients-p-z", title: "Ingredient Prep Tutorials P-Z" },
    { path: "portal-tutorials", title: "Clean Food Dirty Girl Portal Tutorials" },
    { path: "magic-meal-planner-tutorials", title: "Magic Meal Planner Tutorials" },
    { path: "wizard-plans-tutorials", title: "Meal Plans Tutorials - Clean Food Dirty Girl" },
    { path: "recipes-tutorials", title: "Recipes Tutorials - Clean Food Dirty Girl" },
    { path: "watch-learn-tutorials", title: "Watch & Learn Tutorials" },
    { path: "group-coaching-tutorials", title: "Group Coaching Tutorials" },
    { path: "account-membership-tutorials", title: "Account & Membership Tutorials" },
    { path: "account", title: "Account - Clean Food Dirty Girl" },
    { path: "wizard-plan-collections", title: "Plant-Based Meal Plan Collections - Clean Food Dirty Girl" },
    { path: "cooking-journal", title: "Cooking Journal - Clean Food Dirty Girl" },
  ];
  const pflColors = {
    CYAN: "cyan",
    DARKGREY: "darkgrey",
    GREEN: "green",
    LIGHT: "light",
    MAGIC: "magic",
    MED_PURPLE: "med-purple",
    ORANGE: "orange",
    PINK: "pink",
    PURPLE: "purple",
    RED: "red",
    TEAL: "teal",
    YELLOW: "yellow",
  };
  const planInternalNames = {
    MP_MONTHLY: "MP_MONTHLY",
    MP_QUARTERLY: "MP_QUARTERLY",
    MP_ANNUAL: "MP_ANNUAL",
    RV_MONTHLY: "RV_MONTHLY",
  };
  const recipeComponentCategories = {
    BLANK_LINE: "Blank Line",
    INGREDIENT: "Ingredient",
    RECIPE: "Recipe",
    TEXT_ONLY: "Text Only",
  };
  const recipeTypes = {
    ASSEMBLY: "Assembly",
    COMPONENT: "Component",
  };
  const recipeViewContexts = {
    MAGIC_MEAL_PLANNER: "MagicMealPlanner",
    STANDALONE_RECIPE: "StandaloneRecipe",
    WIZARD_PLAN: "WizardPlan",
  };
  const routeTypes = {
    RECIPE_COLLECTION: "RecipeCollection",
    TILE_COLLECTION: "TileCollection",
    VIDEO_PLAYLIST: "VideoPlaylist",
    WIZARD_PLAN_COLLECTION: "WizardPlanCollection",
  };
  /**
   * Excepting the SUCCESS type,
   * these are the possible values of the subscriptionMessage field in useSubscription hook.
   * The SUCCESS type serves only for identifying successful API responses (SubscriptionResource -> SubscriptionManager),
   * therefore it is not complying with the logic of the rest of the values and probably should not be included here.
   */
  const subscriptionMessages = {
    SUCCESS: "SUCCESS",
    ADD_SUBSCRIPTION_SUCCESS: "ADD_SUBSCRIPTION_SUCCESS",
    CHANGE_SUBSCRIPTION_SUCCESS: "CHANGE_SUBSCRIPTION_SUCCESS",
    ERROR: "ERROR",
    ERROR_CARD: "ERROR_CARD",
  };
  const measurementSystems = {
    METRIC: "Metric",
    US: "US",
  };
  const memberTags = {
    KID_FRIENDLY: "Kid friendly",
    OMNI_FRIENDLY: "Omni friendly",
  };
  const searchOrigins = {
    GLOBAL: "global",
    PLANNER: "planner",
  };
  const MMP_SECTION_CAP = 25;
  const MMP_SECTIONS = 14;
  const MP_NAME_CAP = 140;
  const MP_DESCRIPTION_CAP = 300;
  const MAX_SERVINGS = 16;

  return {
    authMessages,
    collectionTypes,
    contexts,
    cookingMethods,
    devices,
    documentTypes,
    groceriesSourceTypes,
    homepageComponentTypes,
    mealTypes,
    pageTitles,
    pflColors,
    planInternalNames,
    recipeComponentCategories,
    recipeTypes,
    recipeViewContexts,
    routeTypes,
    subscriptionMessages,
    measurementSystems,
    memberTags,
    searchOrigins,
    MMP_SECTION_CAP,
    MMP_SECTIONS,
    MP_NAME_CAP,
    MP_DESCRIPTION_CAP,
    MAX_SERVINGS,
  };
}
