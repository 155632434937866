import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Constants from "../../services/Constants";
import Helpers from "../../services/Helpers";
import NewPlanForm from "./NewPlanForm";
import NewSectionForm from "./NewSectionForm";
import ReplaceWarning from "./ReplaceWarning";

export default function AddToMealPlanModal(props) {
  const { mealTypes, documentTypes, MMP_SECTIONS: mmpSections } = Constants();
  const { capitalizeFirstLetter, isNotEmpty } = Helpers();
  const [planId, setPlanId] = useState("");
  const [day, setDay] = useState("");
  const [mealType, setMealType] = useState("");
  const [showNewPlanForm, setShowNewPlanForm] = useState(false);
  const [showNewSectionForm, setShowNewSectionForm] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    if (isNotEmpty(planId)) {
      props.addToPlanStore.getPlan(planId);
    }
  }, [planId]);

  useEffect(() => {
    if (props.addToPlanStore.document.documentType === documentTypes.WIZARD_PLAN) {
      // Get the current meals of the wizard plan
      props.addToPlanStore.addDocumentMeals();
    }
  }, [props.addToPlanStore.document.documentId]);

  useEffect(() => {
    // When a section is created, the modal data is updated, and we select the newest section
    if (isNotEmpty(planId) && props.addToPlanStore.plan.sections.length > 0) {
      setDay(props.addToPlanStore.plan.sections[props.addToPlanStore.plan.sections.length - 1].day);
    }
  }, [props.addToPlanStore.plan]);

  function selectPlan(newPlanId) {
    if (isNotEmpty(newPlanId)) {
      setPlanId(newPlanId);
    }
  }

  function handleAddMealSubmit(event) {
    event.preventDefault();
    setWorking(true);
    props.addToPlanStore.add(
      {
        planId: planId,
        day: day,
        mealType: mealType,
      },
      () => {
        hide();
      }
    );
  }

  function hide() {
    props.hide();
    // Reset a few things
    setPlanId("");
    setDay("");
    setMealType("");
    setShowNewPlanForm(false);
    setWorking(false);
  }

  const newPlanForm = <NewPlanForm store={props.mmpStore} select={selectPlan} hide={() => setShowNewPlanForm(false)} />;

  const newSectionForm = <NewSectionForm store={props.addToPlanStore} hide={() => setShowNewSectionForm(false)} />;

  const planOptions = props.mmpStore.state.plans.map((p) => (
    <option key={p.id} value={p.id}>
      {p.name}
    </option>
  ));

  const planSelect = (
    <div>
      <select id="plan" className="form-select mt-0" value={planId} onChange={(e) => setPlanId(e.target.value)}>
        <option key="plan" value="">
          ...
        </option>
        {planOptions}
      </select>
    </div>
  );

  const plan =
    props.mmpStore.state.plans.length < 20 ? (
      <div className="row align-items-center mb-3">
        <div className="col-12">
          <h3 className="h-alt">Choose Magic Meal Plan</h3>
        </div>
        <div className="col">
          {planSelect}
          <button className="btn btn-link" type="button" onClick={() => setShowNewPlanForm(true)}>
            or create new plan
          </button>
        </div>
      </div>
    ) : (
      <div className="row mb-3">
        <div className="col">
          <p>
            <small>
              Hey there, plant-based boss! You've created the maximum amount of 20 Magic Meal Plans. Add this meal to an existing plan or{" "}
              <Link to="/meal-plans/meal-planner">manage your meal plans</Link>.
            </small>
          </p>
          {planSelect}
        </div>
      </div>
    );

  const sectionOptions = props.addToPlanStore.plan.sections.map((s) => (
    <option key={s.id} value={s.day}>
      {s.name}
    </option>
  ));

  const sectionSelect = (
    <div>
      <h4 className="h-alt">Select section</h4>
      <select id="day" className="form-select" value={day} onChange={(e) => (isNotEmpty(e.target.value) ? setDay(+e.target.value) : setDay(""))}>
        <option key="section" value="">
          ...
        </option>
        {sectionOptions}
      </select>
    </div>
  );

  const section =
    props.addToPlanStore.plan.sections.length < mmpSections ? (
      <div className="row mb-3">
        <div className="col">
          {sectionSelect}
          <button className="btn btn-link" type="button" onClick={() => setShowNewSectionForm(true)}>
            or create new section
          </button>
        </div>
      </div>
    ) : (
      <div className="row mb-3">
        <div className="col-12">{sectionSelect}</div>
      </div>
    );

  const meal =
    props.addToPlanStore.document.documentType === documentTypes.RECIPE && isNotEmpty(day) && !props.addToPlanStore.plan.listView ? (
      <div className="row mb-3">
        <div className="col-12">
          <h4 className="h-alt">Select meal type</h4>
          <small>Go ahead, eat broccoli for breakfast!</small>
          <div className="row">
            <div className="col">
              <button
                className={`btn m-1 ms-0 ${mealType === mealTypes.BREAKFAST ? "btn-primary" : "btn-outline-primary"}`}
                type="button"
                value={mealTypes.BREAKFAST}
                onClick={() => setMealType(mealTypes.BREAKFAST)}
              >
                {capitalizeFirstLetter(mealTypes.BREAKFAST)}
              </button>
              <button
                className={`btn m-1 ${mealType === mealTypes.LUNCH ? "btn-primary" : "btn-outline-primary"}`}
                type="button"
                value={mealTypes.LUNCH}
                onClick={() => setMealType(mealTypes.LUNCH)}
              >
                {capitalizeFirstLetter(mealTypes.LUNCH)}
              </button>
              <button
                className={`btn m-1 ${mealType === mealTypes.DINNER ? "btn-primary" : "btn-outline-primary"}`}
                type="button"
                value={mealTypes.DINNER}
                onClick={() => setMealType(mealTypes.DINNER)}
              >
                {capitalizeFirstLetter(mealTypes.DINNER)}
              </button>
              <button
                className={`btn m-1 ${mealType === mealTypes.EXTRA ? "btn-primary" : "btn-outline-primary"}`}
                type="button"
                value={mealTypes.EXTRA}
                onClick={() => setMealType(mealTypes.EXTRA)}
              >
                Other
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : null;

  const ready = isNotEmpty(day) && (props.addToPlanStore.plan.listView || isNotEmpty(mealType));

  function addButton() {
    let result = null;
    if (isNotEmpty(planId)) {
      let showButton = false;
      let buttonText = "";
      if (props.addToPlanStore.document.documentType === documentTypes.WIZARD_PLAN) {
        // We're adding all the recipes of a Wizard Plan
        buttonText = "Add meal plan recipes";
        showButton = true;
      } else if (ready) {
        // We're adding a single recipe
        buttonText = "Add recipe";
        showButton = true;
      }
      if (showButton) {
        result = working ? (
          <div className="d-flex">
            <button type="button" className="btn btn-primary" disabled={true}>
              <span className="spinner-border spinner-border-sm" />
              <span className="ms-1">Adding...</span>
            </button>
          </div>
        ) : (
          <div className="d-flex">
            <button type="submit" className="btn btn-primary">
              <i className="far fa-plus" /> {buttonText}
            </button>
          </div>
        );
      }
    }
    return result;
  }

  function main() {
    let result;
    if (props.mmpStore.state.plans.length > 0) {
      if (showNewPlanForm) {
        result = newPlanForm;
      } else if (showNewSectionForm) {
        result = newSectionForm;
      } else {
        result = (
          <div className="w-80 mx-auto">
            <form onSubmit={handleAddMealSubmit}>
              {plan}
              {props.addToPlanStore.document.documentType === documentTypes.RECIPE && isNotEmpty(planId) ? section : null}
              {props.addToPlanStore.document.documentType === documentTypes.RECIPE && isNotEmpty(planId) && isNotEmpty(day) ? meal : null}
              <ReplaceWarning store={props.addToPlanStore} day={isNotEmpty(day) ? day : 0} readyToReplace={ready} />
              {addButton()}
            </form>
          </div>
        );
      }
    } else {
      result = newPlanForm;
    }
    return result;
  }

  return (
    <div className="modal fade" id="add-to-meal-plan-modal" tabIndex={-1} data-bs-backdrop="static">
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add {props.addToPlanStore.document.name}</h5>
            {working ? (
              <button type="button" className="btn-close btn-close-black" disabled={true} />
            ) : (
              <button type="button" className="btn-close btn-close-black" onClick={hide} />
            )}
          </div>
          <div className="modal-body">{main()}</div>
        </div>
      </div>
    </div>
  );
}

AddToMealPlanModal.propTypes = {
  addToPlanStore: PropTypes.object,
  mmpStore: PropTypes.object,
  hide: PropTypes.func,
};
