import React from "react";
import PropTypes from "prop-types";
import Constants from "../../services/Constants";
import Helpers from "../../services/Helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCcAmex, faCcDiscover, faCcMastercard, faCcVisa} from "@fortawesome/free-brands-svg-icons";

export default function PurchaseConfirmationCard(props) {
  const {planInternalNames} = Constants();
  const {isNotEmpty} = Helpers();

  function membership() {
    let result = null;
    if (props.plan.internalName === planInternalNames.MP_MONTHLY) {
      result = (
        <>
          <p className="fw-bold">Meal Plan Club - Monthly</p>
          <p className="small">Renews monthly</p>
        </>
      );
    } else if (props.plan.internalName === planInternalNames.MP_QUARTERLY) {
      result = (
        <>
          <p className="fw-bold">Meal Plan Club - Quarterly</p>
          <p className="small">Renews every three months</p>
        </>
      );
    } else if (props.plan.internalName === planInternalNames.MP_ANNUAL) {
      result = (
        <>
          <p className="fw-bold">Meal Plan Club - Annual membership</p>
          <p className="small">Renews annually</p>
        </>
      );
    } else if (props.plan.internalName === planInternalNames.RV_MONTHLY) {
      result = (
        <>
          <p className="fw-bold">Recipe Club - Monthly membership</p>
          <p className="small">Renews monthly</p>
        </>
      );
    }
    return result;
  }

  function amount() {
    let result = null;
    if (props.plan.internalName === planInternalNames.MP_MONTHLY) {
      result = "$25";
    } else if (props.plan.internalName === planInternalNames.MP_QUARTERLY) {
      result = "$60";
    } else if (props.plan.internalName === planInternalNames.MP_ANNUAL) {
      result = "$216";
    } else if (props.plan.internalName === planInternalNames.RV_MONTHLY) {
      result = "$12";
    }
    return result;
  }

  function card() {
    let result = null;
    if (isNotEmpty(props.card)) {
      let logo = null;
      switch (props.card.type) {
        case 1: // Visa
          logo = <FontAwesomeIcon icon={faCcVisa} />;
          break;
        case 2: // Mastercard
          logo = <FontAwesomeIcon icon={faCcMastercard} />;
          break;
        case 3: // American Express
          logo = <FontAwesomeIcon icon={faCcAmex} />;
          break;
        case 4: // Discover
          logo = <FontAwesomeIcon icon={faCcDiscover} />;
          break;
        case 6: // Diner's Club, JCB, UnionPay
          logo = <i className="fa-solid fa-credit-card-front small"></i>
          break;
        default:
          break;
      }
      let last4;
      switch (props.card.last4.toString().length) {
        case 4:
          last4 = `x${props.card.last4}`;
          break;
        case 3:
          last4 = `x0${props.card.last4}`;
          break;
        case 2:
          last4 = `x00${props.card.last4}`;
          break;
        case 1:
          last4 = `x000${props.card.last4}`;
          break;
        default:
          last4 = "x0000";
          break;
      }
      result = (
        <div className="row text-muted align-items-center">
          <div className="col pe-0">{logo}</div>
          <div className="col footer-text">{last4}</div>
        </div>
      );
    }
    return result;
  }

  return (
    <div className="card mb-3">
      <div className="card-body p-3">
        <div className="row">
          <div className="col pe-0">
            {membership()}
          </div>
          <div className="col-auto fs-1 fw-bold text-end">
            {amount()}
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-auto ms-auto">
            {card()}
          </div>
        </div>
      </div>
    </div>
    );
}

PurchaseConfirmationCard.propTypes = {
  plan: PropTypes.object,
  card: PropTypes.object,
}