import React, {useEffect, useReducer} from "react";
import PropTypes from "prop-types";
import {Collapse} from "bootstrap";
import Helpers from "../../../services/Helpers";
import Constants from "../../../services/Constants";

const actions = {
  SHOW: "show",
  CANCEL: "cancel",
  LOAD_MEMBER: "loadMember",
  FIRSTNAME: "firstname",
  LASTNAME: "lastname",
  DISPLAY_NAME: "displayName",
  EMAIL: "email",
  VALIDATE: "validate",
  WORKING: "working",
  SERVER_MESSAGE: "serverMessage",
}

const initialState = {
  show: false,
  firstname: "",
  firstnameInvalid: false,
  lastname: "",
  lastnameInvalid: false,
  displayName: "",
  email: "",
  emailInvalid: false,
  readyToSubmit: false,
  working: false,
}

export default function ProfileForm(props) {
  const {authMessages} = Constants();
  const {isNotEmpty, validateEmail} = Helpers();
  function reducer(state, action) {
    if (action.type === actions.SHOW) {
      return {...state, show: action.data.show};
    } else if (action.type === actions.CANCEL) {
      props.resetMessage();
      return {show: false,  firstname: props.member.firstname, firstnameInvalid: false, lastname: props.member.lastname, lastnameInvalid: false, displayName: props.member.displayName, email: props.member.email, emailInvalid: false, readyToSubmit: false, working: false};
    } else if (action.type === actions.LOAD_MEMBER) {
      return {...state, firstname: props.member.firstname, lastname: props.member.lastname, displayName: props.member.displayName, email: props.member.email, readyToSubmit: false, working: false};
    } else if (action.type === actions.FIRSTNAME) {
      return {...state, firstname: action.data.value, firstnameInvalid: false};
    } else if (action.type === actions.LASTNAME) {
      return {...state, lastname: action.data.value, lastnameInvalid: false};
    } else if (action.type === actions.DISPLAY_NAME) {
      return {...state, displayName: action.data.value};
    } else if (action.type === actions.EMAIL) {
      if (props.message === authMessages.EMAIL_ALREADY_USED) {
        // When editing email, if the previous value was in conflict with an existing email, we reset the message to hide it
        props.resetMessage();
      }
      return {...state, email: action.data.value, emailInvalid: false};
    } else if (action.type === actions.VALIDATE) {
      props.resetMessage();
      const firstnameValid = isNotEmpty(state.firstname);
      const lastnameValid = isNotEmpty(state.lastname);
      const emailValid = validateEmail(state.email);
      const readyToSubmit = firstnameValid && lastnameValid && emailValid;
      return {...state, firstnameInvalid: !firstnameValid, lastnameInvalid: !lastnameValid, emailInvalid: !emailValid, readyToSubmit: readyToSubmit}
    } if (action.type === actions.WORKING) {
      return {...state, working: action.data.working};
    } else if (action.type === actions.SERVER_MESSAGE) {
      return {...state, readyToSubmit: false, working: false};
    }
    return state;
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    return () => props.resetMessage();
  }, []);

  useEffect(() => {
    dispatch({ type: actions.LOAD_MEMBER });
  }, [props.member]);

  useEffect(() => {
    const element = document.getElementById("profile-details");
    const collapse = new Collapse(element, {toggle: false});
    state.show ? collapse.show() : collapse.hide();
  }, [state.show]);

  useEffect(() => {
    if (isNotEmpty(props.message)) {
      dispatch({
        type: actions.SERVER_MESSAGE
      });
    }
  }, [props.message]);

  useEffect(() => {
    if (state.readyToSubmit) {
      dispatch({
        type: actions.WORKING,
        data: { working: true }
      });
    }
  }, [state.readyToSubmit]);

  useEffect(() => {
    if (state.readyToSubmit && state.working) {
      props.update({firstname: state.firstname, lastname: state.lastname, email: state.email, displayName: state.displayName});
    }
  }, [state.working]);

  function handleSubmit(event) {
    event.preventDefault();
    dispatch({type: actions.VALIDATE});
  }

  const message = props.message === authMessages.EMAIL_ALREADY_USED ? (
    <div className="alert alert-primary" role="alert">
      There is already another account using the email <strong>{state.email}</strong>.
    </div>
  ) : null;

  function submitButton() {
    let result = (
      <button
        className="btn btn-primary ms-2"
        type="button"
        disabled={true}
      >
        Save
      </button>
    );
    if (state.working) {
      result = (
        <button
          className="btn btn-primary ms-2"
          type="button"
          disabled={true}
        >
          <span className="spinner-border spinner-border-sm"/>
          <span className="ms-1">Saving...</span>
        </button>
      );
    } else {
      const canSubmit = props.member.firstname !== state.firstname || props.member.lastname !== state.lastname || props.member.displayName !== state.displayName || props.member.email !== state.email;
      if (canSubmit) {
        result = (
          <button
            className="btn btn-primary ms-2"
            type="submit"
          >
            Save
          </button>
        );
      }
    }
    return result;
  }

  return (
    <div className="accordion-item">
      <div className="accordion-header">
        <button
          className={state.show ? "accordion-button" : "accordion-button collapsed"}
          type="button"
          onClick={() => dispatch({ type: actions.SHOW, data: { show: !state.show } })}
        >
          <span>Profile Details</span>
        </button>
      </div>
      <div id="profile-details" className="accordion-collapse collapse">
        <div className="accordion-body">
          {message}
          <div className="row">
            <form onSubmit={handleSubmit} noValidate={true}>
              <div className="mb-3">
                <label htmlFor="firstname" className="form-label">
                  First name
                </label>
                <input
                  type="text"
                  className={state.firstnameInvalid ? "form-control is-invalid" : "form-control"}
                  id="firstname"
                  value={state.firstname}
                  onChange={e => dispatch({ type: actions.FIRSTNAME, data: { value: e.target.value } })}
                />
                <div className="invalid-feedback">
                  Please provide your first name.
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="lastname" className="form-label">
                  Last name
                </label>
                <input
                  type="text"
                  className={state.lastnameInvalid ? "form-control is-invalid" : "form-control"}
                  id="lastname"
                  value={state.lastname}
                  onChange={e => dispatch({ type: actions.LASTNAME, data: { value: e.target.value } })}
                />
                <div className="invalid-feedback">
                  Please provide your last name.
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="displayName" className="form-label">
                  Display name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="displayName"
                  value={state.displayName}
                  onChange={e => dispatch({ type: actions.DISPLAY_NAME, data: { value: e.target.value } })}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className={state.emailInvalid ? "form-control is-invalid" : "form-control"}
                  id="email"
                  value={state.email}
                  onChange={e => dispatch({ type: actions.EMAIL, data: { value: e.target.value } })}
                />
                <div className="invalid-feedback">
                  Please provide a valid email address.
                </div>
              </div>
              <div>
                {state.working ? (
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    disabled={true}
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => dispatch({type: actions.CANCEL})}
                  >
                    Cancel
                  </button>
                )}
                {submitButton()}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

ProfileForm.propTypes = {
  message: PropTypes.string,
  member: PropTypes.object,
  resetMessage: PropTypes.func,
  update: PropTypes.func,
};
