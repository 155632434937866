import React, { useReducer } from "react";
import axios from "axios";
import Helpers from "../Helpers";
import Constants from "../Constants";
import { Link } from "react-router-dom";

const actions = {
  CHANGE_SECTION: "changeSection",
  LOAD_RESULTS: "loadResults",
  LOAD_FACETS: "loadFacets",
  LOADING: "loading",
  LOCK_FACETS: "lockFacets",
  UPDATE_TERMS: "updateTerms",
}

const sections = {
  ALL: "all",
  PINNED: "pinned",
  SAVED: "saved",
  LOVED: "loved",
}

const searchActions = {
  SEARCH_BUTTON: "searchButton",
  SECTION_ALL: "sectionAll",
  SECTION_PINNED: "sectionPinned",
  SECTION_SAVED: "sectionSaved",
  SECTION_LOVED: "sectionLoved",
  PAGER: "pager",
  FACET: "facet",
  RECOMMENDED_ONLY: "recommendedOnly",
  CLEAR_FILTERS: "clearFilters",
}

export default function useUniversalSearch(token, config, preferences) {
  const { buildQuery, isNotEmpty } = Helpers();
  const { documentTypes, searchOrigins } = Constants();
  const initialState = {
    section: sections.ALL,
    recommendedOnly: config.recommendedOnly,
    terms: "",
    results: [],
    activeDocumentType: config.searchOrigin === searchOrigins.PLANNER ? documentTypes.RECIPE : "",
    activeToolIds: [],
    activeCharacteristicIds: [],
    activeTagIds: config.tagIds,
    activePlanType: "",
    total: 0,
    totalPinned: 0,
    totalSaved: 0,
    totalLoved: 0,
    offset: 0,
    facets: {
      documentTypes: [],
      planTypeFacets: [],
      toolFacets: [],
      characteristicFacets: [],
      tagFacets: [],
      magicMealPlannerFacets: [],
    },
    facetLocked: true,
    loading: true,
  }
  function reducer(state, action) {
    if (action.type === actions.LOADING) {
      return { ...state, loading: true };
    } else if (action.type === actions.CHANGE_SECTION) {
      return { ...state, section: action.data.section };
    } else if (action.type === actions.LOAD_RESULTS) {
      if (config.searchOrigin === searchOrigins.PLANNER) {
        // Persist the "Meal Planner friendly" facet for the current Magic Meal Planner session
        config.setRecommendedOnly(action.data.recommendedOnly);
      }
      return {
        ...state,
        section: action.data.section,
        recommendedOnly: action.data.recommendedOnly,
        terms: state.terms === "" ? action.data.terms : state.terms,
        results: action.data.results,
        activeDocumentType: action.data.activeDocumentType,
        activeToolIds: action.data.activeToolIds,
        activeCharacteristicIds: action.data.activeCharacteristicIds,
        activeTagIds: action.data.activeTagIds,
        activePlanType: action.data.activePlanType,
        total: action.data.total,
        totalPinned: action.data.totalPinned,
        totalSaved: action.data.totalSaved,
        totalLoved: action.data.totalLoved,
        offset: action.data.offset,
        loading: false,
      };
    } else if (action.type === actions.LOAD_FACETS) {
      return { ...state, facets: action.data.facets, facetLocked: false };
    } else if (action.type === actions.LOCK_FACETS) {
      return { ...state, facetLocked: true };
    } else if (action.type === actions.UPDATE_TERMS) {
      return { ...state, terms: action.data.terms };
    }
    return state;
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  function changeSection(e, section) {
    e.preventDefault();
    dispatch({
      type: actions.CHANGE_SECTION,
      data: { section: section }
    });
  }

  function updateTerms(terms) {
    dispatch({
      type: actions.UPDATE_TERMS,
      data: { terms: terms }
    });
  }

  function nextLocation(searchAction, data) {
    dispatch({ type: actions.LOADING });
    let prefix;
    if (config.searchOrigin === searchOrigins.PLANNER) {
      prefix = `${config.path}?v=search&`
    } else {
      prefix = `${config.path}?`
    }
    let url = prefix;
    if (searchAction === searchActions.SECTION_ALL) {
      url = `${prefix}tab=${sections.ALL}&mmp-friendly=${state.recommendedOnly}&query=${state.terms}&offset=0&content=${state.activeDocumentType}&wp-type=${state.activePlanType}&tools=${state.activeToolIds.join(",")}&allergens=${state.activeCharacteristicIds.join(",")}&categories=${state.activeTagIds.join(",")}`;
    } else if (searchAction === searchActions.SECTION_PINNED) {
      url = `${prefix}tab=${sections.PINNED}&mmp-friendly=${state.recommendedOnly}&query=${state.terms}&offset=0&content=${state.activeDocumentType}&wp-type=${state.activePlanType}&tools=${state.activeToolIds.join(",")}&allergens=${state.activeCharacteristicIds.join(",")}&categories=${state.activeTagIds.join(",")}`;
    } else if (searchAction === searchActions.SECTION_SAVED) {
      url = `${prefix}tab=${sections.SAVED}&mmp-friendly=${state.recommendedOnly}&query=${state.terms}&offset=0&content=${state.activeDocumentType}&wp-type=${state.activePlanType}&tools=${state.activeToolIds.join(",")}&allergens=${state.activeCharacteristicIds.join(",")}&categories=${state.activeTagIds.join(",")}`;
    } else if (searchAction === searchActions.SECTION_LOVED) {
      url = `${prefix}tab=${sections.LOVED}&mmp-friendly=${state.recommendedOnly}&query=${state.terms}&offset=0&content=${state.activeDocumentType}&wp-type=${state.activePlanType}&tools=${state.activeToolIds.join(",")}&allergens=${state.activeCharacteristicIds.join(",")}&categories=${state.activeTagIds.join(",")}`;
    } else if (searchAction === searchActions.RECOMMENDED_ONLY) {
      url = `${prefix}tab=${state.section}&mmp-friendly=${!state.recommendedOnly}&query=${state.terms}&offset=0&content=${state.activeDocumentType}&wp-type=${state.activePlanType}&tools=${state.activeToolIds.join(",")}&allergens=${state.activeCharacteristicIds.join(",")}&categories=${state.activeTagIds.join(",")}`;
    } else if (searchAction === searchActions.SEARCH_BUTTON) {
      url = `${prefix}tab=${state.section}&mmp-friendly=${state.recommendedOnly}&query=${state.terms}&offset=0&content=${state.activeDocumentType}&wp-type=${state.activePlanType}&tools=${state.activeToolIds.join(",")}&allergens=${state.activeCharacteristicIds.join(",")}&categories=${state.activeTagIds.join(",")}`;
    } else if (searchAction === searchActions.PAGER) {
      url = `${prefix}tab=${state.section}&mmp-friendly=${state.recommendedOnly}&query=${state.terms}&offset=${data}&content=${state.activeDocumentType}&wp-type=${state.activePlanType}&tools=${state.activeToolIds.join(",")}&allergens=${state.activeCharacteristicIds.join(",")}&categories=${state.activeTagIds.join(",")}`;
    } else if (searchAction === searchActions.FACET) {
      dispatch({ type: actions.LOCK_FACETS });
      if (data.facetType === "documentType") {
        const dt = state.activeDocumentType === data.facetName ? "" : data.facetName;
        url = `${prefix}tab=${state.section}&mmp-friendly=${state.recommendedOnly}&query=${state.terms}&offset=0&content=${dt}&wp-type=${state.activePlanType}&tools=${state.activeToolIds.join(",")}&allergens=${state.activeCharacteristicIds.join(",")}&categories=${state.activeTagIds.join(",")}`;
      } else if (data.facetType === "tool") {
        const toolIds = state.activeToolIds.indexOf(data.id) === -1 ? state.activeToolIds.concat([data.id]) : state.activeToolIds.filter(id => id !== data.id);
        url = `${prefix}tab=${state.section}&mmp-friendly=${state.recommendedOnly}&query=${state.terms}&offset=0&content=${state.activeDocumentType}&wp-type=${state.activePlanType}&tools=${toolIds.join(",")}&allergens=${state.activeCharacteristicIds.join(",")}&categories=${state.activeTagIds.join(",")}`;
      } else if (data.facetType === "characteristic") {
        const characteristicIds = state.activeCharacteristicIds.indexOf(data.id) === -1 ? state.activeCharacteristicIds.concat([data.id]) : state.activeCharacteristicIds.filter(id => id !== data.id);
        url = `${prefix}tab=${state.section}&mmp-friendly=${state.recommendedOnly}&query=${state.terms}&offset=0&content=${state.activeDocumentType}&wp-type=${state.activePlanType}&tools=${state.activeToolIds.join(",")}&allergens=${characteristicIds.join(",")}&categories=${state.activeTagIds.join(",")}`;
      } else if (data.facetType === "planType") {
        const pt = state.activePlanType === data.facetName ? "" : data.facetName;
        url = `${prefix}tab=${state.section}&mmp-friendly=${state.recommendedOnly}&query=${state.terms}&offset=0&content=${state.activeDocumentType}&wp-type=${pt}&tools=${state.activeToolIds.join(",")}&allergens=${state.activeCharacteristicIds.join(",")}&categories=${state.activeTagIds.join(",")}`;
      } else if (data.facetType === "tag") {
        const tagIds = state.activeTagIds.indexOf(data.id) === -1 ? state.activeTagIds.concat([data.id]) : state.activeTagIds.filter(id => id !== data.id);
        url = `${prefix}tab=${state.section}&mmp-friendly=${state.recommendedOnly}&query=${state.terms}&offset=0&content=${state.activeDocumentType}&wp-type=${state.activePlanType}&tools=${state.activeToolIds.join(",")}&allergens=${state.activeCharacteristicIds.join(",")}&categories=${tagIds.join(",")}`;
      }
    } else if (searchAction === searchActions.CLEAR_FILTERS) {
      url = `${prefix}tab=${state.section}&mmp-friendly=false&query=${state.terms}&offset=0&content=&wp-type=&tools=${[].join(",")}&allergens=${[].join(",")}&categories=${[].join(",")}`;
    }
    return url;
  }

  function validSearch(search) {
    const searchParams = new URLSearchParams(search);
    if (
      searchParams.get("tab") === null ||
      searchParams.get("mmp-friendly") === null ||
      searchParams.get("query") === null ||
      searchParams.get("offset") === null ||
      searchParams.get("content") === null ||
      searchParams.get("wp-type") === null ||
      searchParams.get("tools") === null ||
      searchParams.get("allergens") === null ||
      searchParams.get("categories") === null
    ) {
      // At least one parameter is missing, we generate a valid string
      const section = searchParams.get("tab") === null ? sections.ALL : searchParams.get("tab");
      // Handle special Magic Meal Planner use case
      let recommendedOnly;
      if (searchParams.get("mmp-friendly") === null) {
        if (config.searchOrigin === searchOrigins.PLANNER) {
          // We use the meal planner persisted value stored in the config
          recommendedOnly = config.recommendedOnly;
        } else {
          recommendedOnly = false;
        }
      } else {
        recommendedOnly = searchParams.get("mmp-friendly");
      }
      const terms = searchParams.get("query") === null ? "" : searchParams.get("query");
      const offset = searchParams.get("offset") === null ? 0 : searchParams.get("offset");
      // Force Recipe document type when searching in the Magic Meal Planner
      let dt = ""
      if (config.searchOrigin === searchOrigins.PLANNER) {
        dt = documentTypes.RECIPE;
      } else if (searchParams.get("content") !== null) {
        dt = searchParams.get("content");
      }
      const pt = searchParams.get("wp-type") === null ? "" : searchParams.get("wp-type");
      const toolIds = searchParams.get("tools") === null ? "" : searchParams.get("tools");
      // Apply allergens when searching for recipes without any characteristics in the URL
      let characteristicIds = "";
      if (dt === documentTypes.RECIPE && searchParams.get("allergens") === null && preferences.allergens.length > 0) {
        characteristicIds = preferences.allergens.map(a => a.id).join(",");
      } else if (searchParams.get("allergens") !== null) {
        characteristicIds = searchParams.get("allergens");
      }
      let tagIds = searchParams.get("categories") === null ? config.tagIds.join(",") : searchParams.get("categories");
      const s = `tab=${section}&mmp-friendly=${recommendedOnly.toString()}&query=${terms}&offset=${offset}&content=${dt}&wp-type=${pt}&tools=${toolIds}&allergens=${characteristicIds}&categories=${tagIds}`;
      if (config.searchOrigin === searchOrigins.PLANNER) {
        return `?v=search&${s}`;
      } else {
        return `?${s}`;
      }
    } else {
      return search;
    }
  }

  function search(search) {
    const query = buildQuery(search);
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/search`,
      data: query,
    }).then(res => {
      if (res.status === 200) {
        dispatch({
          type: actions.LOAD_RESULTS,
          data: res.data
        });
      }
    }).catch(() => {
      // do nothing
    });
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/search/facets`,
      data: query,
    }).then(res => {
      if (res.status === 200) {
        dispatch({
          type: actions.LOAD_FACETS,
          data: { facets: res.data }
        });
      }
    }).catch(() => {
      // do nothing
    });
  }

  function sectionTotal() {
    let result = 0;
    if (state.section === sections.ALL) {
      result = state.total;
    } else if (state.section === sections.PINNED) {
      result = state.totalPinned;
    } else if (state.section === sections.SAVED) {
      result = state.totalSaved;
    } else if (state.section === sections.LOVED) {
      result = state.totalLoved;
    }
    return result;
  }

  function noResultsMessage() {
    let result = null;
    if (state.section === sections.ALL && state.total === 0 && searchIsNotEmpty()) {
      result = "There is no content matching your current search, try to search for something else.";
    } else if (state.section === sections.PINNED && state.totalPinned === 0) {
      result = searchIsNotEmpty() ?
        "There are no pinned plans matching your current search, try to search for something else." : (
          <>
            You don't have any pinned plans yet! Let's change that. <Link to="/search?content=WizardPlan">Browse the meal plans</Link> and prepare to drool!
          </>
        );
    } else if (state.section === sections.SAVED && state.totalSaved === 0) {
      if (config.searchOrigin === searchOrigins.PLANNER) {
        result = searchIsNotEmpty() ?
          "There are no saved recipes matching your current search, try to search for something else." : (
            <>
              You don't have any saved recipes yet! Dive into our <Link to={`${config.path}?v=search&content=Recipe`}>recipes</Link> and save to your heart's desire!
            </>
          );
      } else {
        result = searchIsNotEmpty() ?
          "There are no saved plans or recipes matching your current search, try to search for something else." : (
            <>
              You don't have anything saved yet! Dive into our <Link to="/search?content=Recipe">recipes</Link> and <Link
                to="/search?content=WizardPlan">meal plans</Link> and save to your heart's desire!
            </>
          );
      }
    } else if (state.section === sections.LOVED && state.totalLoved === 0) {
      if (config.searchOrigin === searchOrigins.PLANNER) {
        result = searchIsNotEmpty() ?
          "There are no loved recipes matching your current search, try to search for something else." : (
            <>
              You don't have any loved recipes yet! Dive into our <Link to={`${config.path}?v=search&content=Recipe`}>recipes</Link> and save to your heart's desire!
            </>
          );
      } else {
        result = searchIsNotEmpty() ?
          "There are no loved plans or recipes matching your current search, try to search for something else." : (
            <>
              Find something you love, tap on that heart and it will always be here for you. Start by searching our <Link to="/search?content=Recipe">recipes</Link> and <Link
                to="/search?content=WizardPlan">meal plans</Link>.
            </>
          );
      }
    }
    return result;
  }

  function searchIsNotEmpty() {
    return isNotEmpty(state.terms) || filtersAreNotEmpty();
  }

  function filtersAreNotEmpty() {
    return (
      state.recommendedOnly ||
      isNotEmpty(state.activePlanType) ||
      (config.searchOrigin !== searchOrigins.PLANNER && isNotEmpty(state.activeDocumentType)) ||
      state.activeToolIds.length > 0 ||
      state.activeCharacteristicIds.length > 0 ||
      state.activeTagIds.length > 0
    );
  }

  return {
    sections: sections,
    searchActions: searchActions,
    state: state,
    changeSection: changeSection,
    filtersAreNotEmpty: filtersAreNotEmpty,
    nextLocation: nextLocation,
    noResultsMessage: noResultsMessage,
    updateTerms: updateTerms,
    validSearch: validSearch,
    search: search,
    searchIsNotEmpty: searchIsNotEmpty,
    sectionTotal: sectionTotal,
  }
}