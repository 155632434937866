import "./SignIn.css";
import React, {useEffect, useState} from "react";
import {Link, Redirect, useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import Constants from "../../services/Constants";
import Helpers from "../../services/Helpers";

export default function SignIn(props) {
  const location = useLocation();
  const {authMessages} = Constants();
  const {isNotEmpty, pageTitle} = Helpers();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [nextLocation, setNextLocation] = useState("/");

  useEffect(() => {
    return () => props.resetMessage();
  }, []);

  useEffect(() => {
    document.title = pageTitle(location.pathname);
    if (location.hasOwnProperty("state") && isNotEmpty(location.state)) {
      if (location.state.hasOwnProperty("from") && isNotEmpty(location.state.from)) {
        if (location.state.from.hasOwnProperty("pathname") && isNotEmpty(location.state.from.pathname)) {
          setNextLocation(location.state.from.pathname);
        }
      }
    }
  }, [location]);

  function handleSubmit(event) {
    event.preventDefault();
    props.signIn({ email: email, password: password });
  }

  const header = (
    <div className="row mb-3  pt-1 align-items-center">
      <div className="col">
        <Link className="navbar-brand me-0" to="/">
          <img
            src={`${process.env.PUBLIC_URL}/images/Clean-Food-Dirty-Girl-Main-Logo.png`}
            className="header-logo" alt="Clean Food Dirty Girl"
          />
        </Link>
      </div>
      <div className="col-auto">
        <Link to="/sign-up?utm_campaign=join&utm_source=portal&utm_medium=link&utm_content=sign+in+header" className="">Start membership</Link>
      </div>
    </div>
  );

  function message() {
    let result = null;
    if (props.message === authMessages.AUTHENTICATION_FAILED) {
      result = (
        <div className="alert alert-primary" role="alert">
          Your email address or password looks incorrect. Please try again or <a href="https://members.cleanfooddirtygirl.com/forgot-password">reset your password</a>. If you're still having trouble, contact our <a href="mailto:support@cleanfooddirtygirl.com" target="_blank">support</a>.
        </div>
      );
    }
    return result;
  }

  return props.authenticated ? (
    <Redirect to={{ pathname: nextLocation }} />
  ) : (
      <main id="sign-in" className="container-lg mt-0 my-sm-auto p-3 p-sm-4">
        {header}
        {message()}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="input-email" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="input-email"
              value={email}
              required={true}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                autoComplete="on"
                className="form-control"
                id="password"
                value={password}
                required={true}
                onChange={e => setPassword(e.target.value)}
              />
              <button
                className="btn btn-outline-light"
                id="btn-show-password"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                title={showPassword ? "Hide password" : "Show password"}
              >
                <i className={`far ${showPassword ? "fa-eye" : "fa-eye-slash"}`}></i>
              </button>
            </div>
          </div>
          <div className="d-grid gap-2 col-12 col-sm-6 mx-auto">
            <button
              className="btn btn-primary btn-lg fw-bold"
              type="submit"
            >
              Sign in
            </button>
          </div>
        </form>
        <p className="mt-2 mb-1 footer-text text-center">
          Forgot your password? <Link to="/forgot-password" className="black-link">Request a link to reset it.</Link>
        </p>
        <p className="mt-0 footer-text text-center">
          Don't have an account? Let's get this party started<br />
          and <Link to="/trial?utm_campaign=trial&utm_source=portal&utm_medium=link&utm_content=sign+in+footer" className="black-link">start a free 10-day trial</Link> or learn about <a className="black-link" href="https://cleanfooddirtygirl.com/plant-fueled-life" target="_blank" rel="noreferrer">Clean Food Dirty Girl</a>.
        </p>

      </main>
  );

}


SignIn.propTypes = {
  authenticated: PropTypes.bool,
  signIn: PropTypes.func,
  resetMessage: PropTypes.func,
  message: PropTypes.string,
}