import "./Document.scss";
import React from "react";
import PropTypes from "prop-types";
import {Link, useHistory} from "react-router-dom";
import Helpers from "../../../services/Helpers";
import Constants from "../../../services/Constants";
import ReviewStars from "../../components/ReviewStars";
import ReviewTiming from "../../components/ReviewTiming";
import RecipeTiming from "../../Recipe/components/RecipeTiming";
import StashButton from "./StashButton";

export default function Document(props) {
  let history = useHistory();
  const { contexts, documentTypes } = Constants();
  const { assetUrl, isNotEmpty, splitHashtag } = Helpers();

  const stashItem = props.stashStore.items.filter(i => i.documentType === props.document.documentType).find(i => i.documentId === props.document.documentId);

  function stars() {
    let result = null;
    if ([documentTypes.WIZARD_PLAN, documentTypes.RECIPE].indexOf(props.document.documentType) !== -1) {
      result = (
        <ReviewStars
          stars={props.document.stars}
          cta={() => props.authenticated ? props.modals.showReviews({
            documentId: props.document.documentId,
            documentType: props.document.documentType,
            reviewsFor: props.document.documentType === documentTypes.RECIPE ? `${props.document.title}` : `${splitHashtag(props.document.title)}`,
          }) : null}
        />
      );
    }
    return result;
  }
  function documentType() {
    let result = null;
    if (props.document.documentType === documentTypes.WIZARD_PLAN) {
      result = (
        <div className="row align-items-center pt-1 text-muted document-type">
          <div className="col">Meal plan</div>
          <div className="col-auto">{stars()}</div>
        </div>
      );
    } else if (props.document.documentType === documentTypes.RECIPE) {
      result = (
        <div className="row align-items-center pt-1 text-muted document-type">
          <div className="col">Recipe</div>
          <div className="col-auto">{stars()}</div>
        </div>
      );
    } else if (props.document.documentType === documentTypes.VIDEO) {
      result = <div className="pt-1 text-muted document-type">Video</div>;
    }
    return result;
  }

  function title() {
    let result;
    if (props.document.documentType === documentTypes.WIZARD_PLAN) {
      result = <Link to={props.document.link} onClick={e => {
        e.preventDefault();
        if (props.member.hasMP) {
          history.push(props.document.link);
        } else {
          props.modals.showFullMembershipRequiredModal();
        }
      }}><p className="p-0 document-title">{splitHashtag(props.document.title)}</p></Link>;
    } else {
      result = <Link to={props.document.link}><p className="p-0 document-title">{props.document.title}</p></Link>;
    }
    return result;
  }

  function timing() {
    let result = null;
    if (props.document.documentType === documentTypes.WIZARD_PLAN) {
      result = (
        <>
          <ReviewTiming
            prefix="Batch time: "
            style={{ fontSize: "0.8rem" }}
            timing={props.document.timing}
            muted={true}
            cta={() => props.authenticated ? props.modals.showReviews({
              documentId: props.document.documentId,
              documentType: props.document.documentType,
              reviewsFor: props.document.documentType === documentTypes.RECIPE ? `${props.document.title}` : `${splitHashtag(props.document.title)}`,
            }) : null}
          />
        </>

      );
    } else if (props.document.documentType === documentTypes.RECIPE) {
      result = (
        <RecipeTiming context={contexts.DOCUMENT} style={{ fontSize: "0.8rem" }} prepTime={props.document.prepTime} readyIn={props.document.readyIn} />
      );
    }
    return result;
  }

  function stashButtons() {
    let result = null;
    if ([documentTypes.WIZARD_PLAN, documentTypes.RECIPE].indexOf(props.document.documentType) !== -1) {
      let add = null;
      if (props.member.hasMP) {
        if (isNotEmpty(props.add)) {
          add = function (event) {
            event.stopPropagation()
            props.add(props.document.documentId);
          }
        } else {
          add = function () {
            props.modals.showAddToMealPlan({
              documentId: props.document.documentId,
              documentType: props.document.documentType,
              name: props.document.title
            })
          }
        }
      }
      const save = (
        <StashButton
          documentId={props.document.documentId}
          documentType={props.document.documentType}
          smallButton={true}
          margin=" me-1"
          title="Save"
          active={stashItem === undefined ? false : stashItem.saved}
          activeClassName="fa fa-folder-bookmark"
          inactiveClassName="far fa-folder-bookmark"
          toggle={props.stashStore.toggleSaved}
        />
      );
      const love = (
        <StashButton
          documentId={props.document.documentId}
          documentType={props.document.documentType}
          smallButton={true}
          margin=" me-1"
          title="Love"
          active={stashItem === undefined ? false : stashItem.loved}
          activeClassName="fa fa-heart"
          inactiveClassName="far fa-heart"
          toggle={props.stashStore.toggleLove}
        />
      );
      if (props.document.documentType === documentTypes.WIZARD_PLAN) {
        result = (
          <div className="row document-buttons">
            <div className="col">
              {props.member.hasMP && (
                <button
                  className="btn btn-sm btn-light me-1"
                  type="button"
                  title="Add to Meal Planner"
                  onClick={event => add(event)}
                >
                  <span className="fa fa-plus"/>
                </button>
              )}
              {props.document.documentType === documentTypes.WIZARD_PLAN ? (
                <StashButton
                  documentId={props.document.documentId}
                  documentType={documentTypes.WIZARD_PLAN}
                  smallButton={true}
                  margin=" me-1"
                  title="Pin"
                  active={stashItem === undefined ? false : stashItem.pinned}
                  activeClassName="fas fa-thumbtack"
                  inactiveClassName="far fa-thumbtack"
                  toggle={props.stashStore.togglePin}
                />
              ) : null}
              {save}
              {love}
            </div>
          </div>
        );
      } else if (props.document.documentType === documentTypes.RECIPE) {
        result = (
          <div className="row document-buttons">
            <div className="col">
              {/* Only full membership subscribers can add to the meal planner */}
              {props.member.hasMP && (
                <button
                  className="btn btn-sm btn-light me-1"
                  type="button"
                  title="Add to Meal Planner"
                  onClick={event => add(event)}
                >
                  <span className="fa fa-plus" />
                </button>
              )}
              {save}
              {love}
            </div>
          </div>
        );
      }
    }
    return result;
  }

  const listView = props.member.preferences.searchListViewOnMobile ? (
    <div className={props.forceListView ? "list-view px-0" : "d-sm-none list-view px-0"}>
      <div className="row mx-1">
        <div className="col-auto ps-0 pe-1">
          <Link
            to={props.document.link}
            onClick={e => {
              e.preventDefault();
              if (props.document.documentType === documentTypes.WIZARD_PLAN && !props.member.hasMP) {
                props.modals.showFullMembershipRequiredModal();
              } else {
                history.push(props.document.link);
              }
            }}
          >
            <img
              src={isNotEmpty(props.document.photo) ? assetUrl(props.document.photo) : `${process.env.PUBLIC_URL}/images/recipe-placeholder-picture.png`}
              alt="Search result photo"
              className="mt-1"
            />
          </Link>
        </div>
        <div className="col pe-0">
          {documentType()}
          {title()}
          {timing()}
          {stashButtons()}
        </div>
      </div>
    </div>
  ) : null;

  const cardViewClassName = props.member.preferences.searchListViewOnMobile ? "d-none d-sm-block" : "px-2 px-sm-0 pt-2 pt-sm-0";

  return (
    <article className={isNotEmpty(props.className) ? props.className : "document col-12 col-sm-6 col-md-3 mb-3 px-sm-2"}>
      {listView}
      {props.forceListView ? null : (
        <div className={cardViewClassName}>
          <div className="photo">
            <Link
              to={props.document.link}
              onClick={e => {
                e.preventDefault();
                if (props.document.documentType === documentTypes.WIZARD_PLAN && !props.member.hasMP) {
                  props.modals.showFullMembershipRequiredModal();
                } else {
                  history.push(props.document.link);
                }
              }}
            >
              <img
                src={isNotEmpty(props.document.photo) ? assetUrl(props.document.photo) : `${process.env.PUBLIC_URL}/images/recipe-placeholder-picture.png`}
                alt="Search result photo"
              />
            </Link>
          </div>
          {documentType()}
          <div>
            {title()}
            {timing()}
            {props.authenticated ? stashButtons() : null}
          </div>
        </div>
      )}
    </article>
  );
}

Document.propTypes = {
  authenticated: PropTypes.bool,
  member: PropTypes.object,
  stashStore: PropTypes.object,
  modals: PropTypes.object,
  document: PropTypes.object,
  add: PropTypes.func,
  className: PropTypes.string,
  forceListView: PropTypes.bool,
}