import React from "react";
import PropTypes from "prop-types";
import Constants from "../../../services/Constants";
import Helpers from "../../../services/Helpers";
import Popover from "../../components/Popover";

export default function FacetOption(props) {
  const {documentTypes} = Constants();
  const {assetUrl, isNotEmpty} = Helpers();

  const facetName = props.facet.facetName === documentTypes.WIZARD_PLAN ? "Meal plan" : props.facet.facetName;

  const tooltip = props.tooltip !== undefined ? (
    <Popover content={props.tooltip} iconClassName="fas fa-circle-question text-secondary"/>
  ) : null;
  const facet = props.checked ? (
    <>
      <div className="col-auto p-0">
        <i className="cursor-pointer fa-solid fa-check text-white checkbox checkbox-filled" />
      </div>
      <div className="col">
        {isNotEmpty(props.image) ? <img src={assetUrl(props.image)} className="tool-image" /> : null}
        {facetName} {tooltip}
      </div>
      <div className="col-auto">
        <span className="facet-count">{props.facet.count}</span>
      </div>
    </>
  ) : (
    <>
      <div className="col-auto p-0">
        <i className="cursor-pointer far fa-check text-white checkbox" />
      </div>
      <div className="col">
        {isNotEmpty(props.image) ? <img src={assetUrl(props.image)} className="tool-image" /> : null}
        {facetName} {tooltip}
      </div>
      <div className="col-auto">
        <span className="facet-count">{props.facet.count}</span>
      </div>
    </>
  );
  function render() {
    let result;
    if (props.locked) {
      result = (
        <div className="row mb-1 align-items-center">
          {facet}
        </div>
      );
    } else {
      result = (
        <div className="row mb-1 align-items-center cursor-pointer" onClick={() => props.toggle(props.facet, props.checked)}>
          {facet}
        </div>
      );
    }
    return result;
  }

  return render();
}

FacetOption.propTypes = {
  locked: PropTypes.bool,
  facet: PropTypes.object,
  checked: PropTypes.bool,
  toggle: PropTypes.func,
  image: PropTypes.string,
  tooltip: PropTypes.string,
}