import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Constants from "../../../services/Constants";
import MealPreview from "./MealPreview";
import Helpers from "../../../services/Helpers";

export default function MagicMealPlanSection(props) {
  const { mealTypes, MMP_SECTION_CAP: mmpSectionCap, MMP_SECTIONS: mmpSections } = Constants();
  const { capitalizeFirstLetter } = Helpers();
  const [name, setName] = useState("");
  const [showSection, setShowSection] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [working, setWorking] = useState(false);

  const sectionId = `section-${props.section.day}`;

  useEffect(() => {
    if (!props.mmpStore.state.plan.listView && props.bookmarked) {
      setShowSection(true);
    }
  }, []);

  useEffect(() => {
    setName(props.section.name);
    setShowEditForm(false);
    setWorking(false);
  }, [props.section]);

  useEffect(() => {
    if (props.mmpStore.state.plan.listView && !showSection) {
      setShowSection(true);
    }
  }, [props.mmpStore.state.plan.listView]);

  useEffect(() => {
    if (!props.mmpStore.state.plan.listView && showSection) {
      props.bookmark();
    }
  }, [showSection]);

  function handleSubmit(e) {
    e.preventDefault();
    setWorking(true);
    props.mmpStore.updateSection({ id: props.section.id, name: name });
  }

  const addMealButton =
    props.section.meals.length < mmpSectionCap ? (
      <div className="dropdown">
        <button
          className="btn btn-sm btn-secondary dropdown-toggle mt-1"
          type="button"
          id={`${props.section.day}-add-meal-button`}
          data-bs-toggle="dropdown"
        >
          Add recipe
        </button>
        <ul className="dropdown-menu">
          <li className="dropdown-item disabled">Filter by:</li>
          <li className="dropdown-item cursor-pointer" onClick={() => props.selectMeal({ day: props.section.day, mealType: mealTypes.BREAKFAST })}>
            {capitalizeFirstLetter(mealTypes.BREAKFAST)}
          </li>
          <li className="dropdown-item cursor-pointer" onClick={() => props.selectMeal({ day: props.section.day, mealType: mealTypes.LUNCH })}>
            {capitalizeFirstLetter(mealTypes.LUNCH)}
          </li>
          <li className="dropdown-item cursor-pointer" onClick={() => props.selectMeal({ day: props.section.day, mealType: mealTypes.DINNER })}>
            {capitalizeFirstLetter(mealTypes.DINNER)}
          </li>
          <li className="dropdown-item cursor-pointer" onClick={() => props.selectMeal({ day: props.section.day, mealType: mealTypes.EXTRA })}>
            All
          </li>
        </ul>
      </div>
    ) : null;

  const sectionFooter = (
    <div className="row align-items-center">
      {copySection()}
      <div className="col text-start mt-1 pe-0">
        {props.mmpStore.state.plan.sections.length > 1 ? (
          <div className="dropdown">
            <button className="btn btn-sm btn-light text-muted" type="button" title="Remove Section" data-bs-toggle="dropdown">
              <span className="text-nowrap">
                <i className="fas fa-circle-minus" /> Remove
              </span>
            </button>
            <ul className="dropdown-menu">
              <li>
                <button type="button" className="btn btn-link dropdown-item" onClick={() => props.mmpStore.deleteSection(props.section.id)}>
                  Remove Section
                </button>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
      <div className="col text-end">{addMealButton}</div>
    </div>
  );

  function copySection() {
    let result = null;
    // show Copy Section button if there are less than 7 sections/days in the magic meal plan and the section has recipes
    if (props.mmpStore.state.plan.sections.length < mmpSections && props.section.meals.length > 0) {
      result = (
        <div className="col-auto text-start mt-1 pe-0">
          <button className="btn btn-sm btn-light" type="button" title="Copy Section" onClick={() => props.mmpStore.copyDay(props.section.day)}>
            <span className="text-nowrap">
              <i className="fas fa-copy" /> {props.mmpStore.state.plan.listView ? "Copy" : "Copy"}
            </span>
          </button>
        </div>
      );
    }
    return result;
  }

  function meals() {
    let result;
    const orderedMeals = [];
    props.section.meals.filter((m) => m.mealType === mealTypes.BREAKFAST).map((m) => orderedMeals.push(m));
    props.section.meals.filter((m) => m.mealType === mealTypes.LUNCH).map((m) => orderedMeals.push(m));
    props.section.meals.filter((m) => m.mealType === mealTypes.DINNER).map((m) => orderedMeals.push(m));
    props.section.meals.filter((m) => m.mealType === mealTypes.EXTRA).map((m) => orderedMeals.push(m));
    result = orderedMeals.map((m) => (
      <MealPreview
        key={m.id}
        stashStore={props.stashStore}
        listView={props.mmpStore.state.plan.listView}
        id={m.id}
        meal={m}
        mealType={m.mealType}
        specialScalingIds={props.mmpStore.specialScalingIds}
        changePortions={props.mmpStore.changePortions}
        toggleMakeAhead={props.mmpStore.toggleMakeAhead}
        removeMeal={props.mmpStore.removeMeal}
        selectMeal={props.selectMeal}
        viewMeal={props.viewMeal}
        changePortionsInRecipeVault={props.mmpStore.changePortionsInRecipeVault}
      />
    ));
    return result;
  }

  const main = (
    <div>
      {props.section.day === 1 && props.section.meals.length === 0 ? (
        <div className="text-center mt-0 my-3 my-md-2 mb-md-3 d-print-none">
          <small className="text-muted">Start adding recipes to your meal plan.</small>
        </div>
      ) : null}
      {props.mmpStore.state.plan.listView ? (
        <div className="list-group pb-0">
          {meals()}
          {sectionFooter}
        </div>
      ) : (
        <>
          <div className="row row-cols-1 row-cols-lg-2 row-cols-xxl-3">{meals()}</div>
          {sectionFooter}
        </>
      )}
    </div>
  );

  const cancelButton = working ? (
    <button type="button" className="btn btn-outline-primary" disabled={true}>
      Cancel
    </button>
  ) : (
    <button
      type="button"
      className="btn btn-outline-primary"
      onClick={(e) => {
        e.stopPropagation();
        setShowEditForm(false);
        setName(props.section.name);
      }}
    >
      Cancel
    </button>
  );

  const submitButton = working ? (
    <button type="button" className="btn btn-primary" disabled={true}>
      <span className="spinner-border spinner-border-sm" />
      <span className="ms-1">Saving...</span>
    </button>
  ) : (
    <button type="submit" className="btn btn-primary">
      Save Changes
    </button>
  );

  return showEditForm ? (
    <div className={"accordion-item mb-3"}>
      <form className="row mb-3 py-3 px-2" onSubmit={handleSubmit}>
        <div className="col">
          <input
            type="text"
            autoComplete="off"
            className="form-control"
            value={name}
            placeholder="Name your Section..."
            required={true}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="col-auto ps-0">{cancelButton}</div>
        <div className="col-auto ps-0">{submitButton}</div>
      </form>
      {/* we keep this empty div to allow the collapse function to continue working */}
      <div id={sectionId} className="accordion-collapse collapse"></div>
    </div>
  ) : (
    <div className={"accordion-item mb-3"}>
      <h2 className="accordion-header">
        <div className="row">
          <div className="col">
            <button
              className={showSection ? "accordion-button" : "accordion-button collapsed"}
              type="button"
              onClick={() => setShowSection(!showSection)}
            >
              {props.section.name}
              <span
                className="far fa-pencil text-muted ms-2 fa-action-text d-print-none"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowEditForm(true);
                }}
              />
            </button>
          </div>
        </div>
      </h2>

      <div id={sectionId} className={showSection ? "accordion-collapse" : "accordion-collapse collapse"}>
        <div className="accordion-body accordion-recipe px-2 py-3 p-md-3">{main}</div>
      </div>
    </div>
  );
}

MagicMealPlanSection.propTypes = {
  stashStore: PropTypes.object,
  mmpStore: PropTypes.object,
  section: PropTypes.object,
  bookmarked: PropTypes.bool,
  selectMeal: PropTypes.func,
  viewMeal: PropTypes.func,
  bookmark: PropTypes.func,
};
