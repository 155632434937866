import React, { useEffect, useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";

export default function MembershipAdvantages({ title, isAuthenticated }) {
  return (
    <main id="members-only" className="container-lg">
      <div className="text-center">
        <h1 className="text-center page-title mb-3">{title}</h1>
        {isAuthenticated ? (
          <Link to="/account?m=become-a-member" className="btn btn-primary btn-lg">
            Join Clean Food Dirty Girl
          </Link>
        ) : (
          <>
            <div className="d-grid gap-2 col-12 col-sm-6 col-md-4 mx-auto">
              <Link to="/sign-up?utm_campaign=join&utm_source=portal&utm_medium=button&utm_content=members+only" className="btn btn-secondary btn-lg mx-1">
                Join
              </Link>
            </div>
            <p className="mt-2">
              Want a taste? <Link to="/trial?utm_campaign=trial&utm_source=portal&utm_medium=link&utm_content=members+only">Start a free 10-day trial</Link>
            </p>
          </>
        )}
      </div>
      <div className="row mt-3">
        <div className="col-12 col-md-6">
          <div className="mt-4 px-2">
            <h3 className="h-alt px-1 mb-3">Our plant-based recipes check all the boxes:</h3>
            <ul className="list-group">
              <li className="list-group-item">✔️ No meat, dairy, or eggs</li>
              <li className="list-group-item">✔️ No oil</li>
              <li className="list-group-item">✔️ Minimal sugar</li>
              <li className="list-group-item">✔️ Gluten-free friendly</li>
              <li className="list-group-item">✔️ Allergy friendly</li>
              <li className="list-group-item">✔️ Developed by our team</li>
              <li className="list-group-item">✔️ Taste tested & approved</li>
              <li className="list-group-item">✔️ Insanely delicious</li>
              <li className="list-group-item">✔️ Optional nutrition info</li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="mt-4 px-2">
            <h3 className="h-alt px-1 mb-3 pt-2 pt-md-0">Your membership includes access to:</h3>
            <div className="accordion accordion-flush" id="accordionFlushExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed fs-6"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Hancrafted plant-based meal plans
                  </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Hundreds of whole-food plant-based meal plans created by our recipe developers to tantalize your tastebuds and nourish your body. Complete with a grocery list, batching instructions, and
                    interactive tools to make mealtime enjoyable again.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed fs-6"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Exclusive plant-based recipes
                  </button>
                </h2>
                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Over 4,900 of the most delicious plant-based recipes you won't find anywhere else. Say goodbye to pesky ads and Pinterest fails. Say hello to your kitchen BFF.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed fs-6"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Magic Meal Planner
                  </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    Make a custom meal plan complete with breakfast, lunch, dinner, and extras for the week and share it with other members. Add custom items to your list, switch view modes and create
                    magic in your mouth.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed fs-6"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Loving support
                  </button>
                </h2>
                <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">Email support from a team who is here for you and cheering you on.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isAuthenticated && (
        <div className="d-grid gap-2 col-12 col-sm-6 col-md-4 mx-auto my-4">
          <a href="https://cleanfooddirtygirl.com/plant-fueled-life/?utm_campaign=join&utm_source=portal&utm_medium=button&utm_content=members+only" className="btn btn-secondary btn-lg mx-1">
            Learn more
          </a>
        </div>
      )}
    </main>
  );
}
