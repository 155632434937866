import { Link } from "react-router-dom";
import ReviewStars from "../../components/ReviewStars";
import Review from "../../components/Review";
import AnonymousFooter from "../../components/AnonymousFooter";
import AnonymousCallToActions from "../../components/AnonymousCallToActions";
import Constants from "../../../services/Constants";
import Helpers from "../../../services/Helpers";
import CFDGHelpListicle from "../../components/CFDGHelpListicle";

export default function PublicRecipe({ recipeStore, reviewStore, modals }) {
  const { documentTypes } = Constants();
  const { assetUrl, isEmpty } = Helpers();
  const reviews = reviewStore.reviewData.reviews.filter((r) => r.text || r.photo);

  return (
    <div className="container-fluid p-0 p-sm-3 p-md-4 strip-padding">
      <div className="row gx-0 justify-content-md-center recipe-wrap bg-white">
        <div className="col-md-5 bg-white">
          <img
            className="img-fluid recipe-featured-image rounded"
            src={assetUrl(recipeStore.state.publicData.photo)}
            alt={`Whole-Food Plant-Based ${recipeStore.state.publicData.name}`}
          />
        </div>

        <div className="col-sm-12 col-md-7 p-3 p-md-4 my-auto bg-white">
          <ReviewStars
            stars={recipeStore.state.publicData.stars}
            cta={(event) => {
              event.stopPropagation();
              modals.showReviews({
                documentId: `${recipeStore.state.publicData.id}`,
                documentType: documentTypes.RECIPE,
                reviewsFor: `${recipeStore.state.publicData.name}`,
              });
            }}
          />
          <h1>{recipeStore.state.publicData.name}</h1>
          <span className="allergens text-muted d-print-none small">Oil-free, dairy-free, no animal products, damn delicious</span>
          <p className="lead">{recipeStore.state.publicData.description}</p>
          {/* <Allergens allergens={recipeStore.state.publicData.allergens} /> */}
          <Link
            to={`/trial?utm_campaign=trial&utm_source=portal&utm_medium=button&utm_content=recipe+trial&utm_term=${recipeStore.state.publicData.id}`}
            className="btn btn-primary btn-lg m-1 ms-0"
          >
            View recipe with a free trial
          </Link>
          <p className="text-muted">
            <small>
              No credit card <em>or</em> perfection required.
            </small>
          </p>
        </div>
      </div>
      <div className="row gx-0 justify-content-md-center recipe-wrap bg-white mt-5">
        <div className="col-12 col-md-7 p-3">
          <div>
            <div className="public-reviews">
              <h2>Reviews</h2>
              {reviews.length ? (
                <ul className="list-group ratings">
                  {reviews.map((r) => (
                    <li key={r.id} className="list-group-item">
                      <Review review={r} memberReview={false} noPhotoLayout={isEmpty(r.photo)} />
                    </li>
                  ))}
                </ul>
              ) : (
                <p>
                  No reviews yet! Let’s change that.{" "}
                  <Link
                    to={`/trial?utm_campaign=trial&utm_source=portal&utm_medium=button&utm_content=recipe+trial&utm_term=${recipeStore.state.publicData.id}`}
                  >
                    Make this recipe with a free trial
                  </Link>
                  .
                </p>
              )}
            </div>
            <h2 className="mt-4">Why can’t I see this recipe?</h2>
            <p>
              Clean Food Dirty Girl is a paid membership. We have thousands of the best whole-food plant-based recipes waiting for you. Start a free
              trial today and see you around!
            </p>
          </div>
        </div>

        <div className="col-12 col-md-5 p-3">
          <div>
            <div className="card bg-primary mb-3">
              <div className="card-body sales-card text-center">
                <a
                  href="https://plantfueledlife.members-only.online/1?utm_campaign=guides&utm_source=portal&utm_medium=public&utm_content=beginners+guide"
                  target="_blank"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/Beginners-Guide-to-Eating-More-Plants.png`} alt="Download your free Beginner's Guide" />
                </a>
                <h2 className="card-title text-white">New?</h2>
                <p className="card-text text-white mb-2">
                  Download your free Beginner's Guide and start the most delicious and rewarding journey of your life, fueled by plants.
                </p>
                <a
                  href="https://plantfueledlife.members-only.online/1?utm_campaign=guides&utm_source=portal&utm_medium=public&utm_content=beginners+guide"
                  className="btn btn-light"
                  target="_blank"
                >
                  Get your Guide
                </a>
              </div>
            </div>
            <CFDGHelpListicle />
            {/* end right column -> */}
          </div>
        </div>
      </div>
      <AnonymousFooter />
      <AnonymousCallToActions
        tellMeMoreLink={`https://cleanfooddirtygirl.com/plant-fueled-life/?utm_campaign=join&utm_source=portal&utm_medium=button&utm_content=recipe+tell+me+more&utm_term=${recipeStore.state.publicData.id}`}
        joinLink={`/trial?utm_campaign=trial&utm_source=portal&utm_medium=button&utm_content=recipe+trial+footer&utm_term=${recipeStore.state.publicData.id}`}
      />
    </div>
  );
}
