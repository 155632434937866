import React from "react";
import PropTypes from "prop-types";

export default function Plan(props) {
  const buttonText = props.selectedPlan === props.plan.internalName ? (
    <>
      Selected <i className="fa-solid fa-check"></i>
    </>
  ) : "Select";

  function toggle() {
    if (!props.readOnly) {
      if (props.plan.internalName !== props.currentPlan) {
        props.selectPlan(props.selectedPlan === props.plan.internalName ? "" : props.plan.internalName);
      }
    }
  }

  function renderPlanButton() {
    let result;
    if (props.currentPlan === props.plan.internalName) {
      result = (
        <button
          type="button"
          className="btn btn-primary font-weight-bold case my-2"
          disabled
        >
          Current
        </button>
      );
    } else {
      result = (
        <button
          type="button"
          className="btn btn-primary font-weight-bold my-2"
          onClick={toggle}
        >
          {buttonText}
        </button>
      );
    }
    return result;
  }

  function classNameText() {
    let result = null;
    if (props.readOnly || props.plan.internalName === props.currentPlan) {
      result = "card mb-3";
    } else if (props.selectedPlan === props.plan.internalName) {
      result = "card mb-3 cursor-pointer border-primary border-5";
    } else {
      result = "card mb-3 cursor-pointer";
    }
    return result;
  }

  return (
    <div
      className={classNameText()}
      onClick={toggle}>
      <div className="card-body p-2">
        <p className={`card-title fs-4 ${props.plan.internalName === props.selectedPlan ? "text-primary" : "text-dark text-opacity-50"}`}>{props.title}</p>
        <div className="row">
          <div className="col">
            {props.content}
          </div>
          {props.readOnly ? null : (
            <div className="d-grid gap-2 col-12 col-lg-4 m-auto">
              {renderPlanButton()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

Plan.propTypes = {
  plan: PropTypes.object,
  title: PropTypes.element,
  content: PropTypes.element,
  readOnly: PropTypes.bool,
  selectedPlan: PropTypes.string,
  selectPlan: PropTypes.func,
  currentPlan: PropTypes.string,
}